import React, { useState } from "react";
import { Hero } from "UI/atoms/Carousel/Hero";
import { Subtitle } from "UI/atoms/Carousel/Subtitle";
import { Tag } from "UI/atoms/Carousel/Tag";
import { Title } from "UI/atoms/Carousel/Title";
import { LibraryResource } from "loader/library-loader";

type CardGridProps = {
    data: LibraryResource[]
}

export const CardGrid = ({ data }: CardGridProps) => {
    const [loadingItems, setLoadingItems] = useState<{ [key: string]: boolean }>({});

    const formatDate = (date: Date | string): string => {
        const dateObj = date instanceof Date ? date : new Date(date);
        return dateObj.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    }

    const isNew = (date: Date | string, type: string): boolean => {
        const createdDate = new Date(date);
        const now = new Date();
        const monthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        return now.getTime() - createdDate.getTime() <= monthInMilliseconds;
    }

    const isDownloadableFile = (url: string): boolean => {
        if (!url) return false;
        
        // Check if it's a Hubspot PDF
        if (url.includes('hubfs') && url.toLowerCase().endsWith('.pdf')) {
            return true;
        }

        // Check if it's a Google Drive file
        if (url.includes('drive.google.com')) {
            // Check if it's a direct file (PDF or other document)
            if (url.includes('/file/d/')) {
                return true;
            }
            // Check if it's a Google Sheets or other Google Doc
            if (url.includes('/spreadsheets/d/') || 
                url.includes('/document/d/') || 
                url.includes('/presentation/d/')) {
                return true;
            }
        }

        return false;
    }

    const handleDownload = async (e: React.MouseEvent, url: string, index: number) => {
        e.preventDefault();
        e.stopPropagation();
    
        // Set loading state for this specific item
        setLoadingItems(prev => ({ ...prev, [index]: true }));
    
        try {
            let downloadUrl = url;
    
            // Detectar si es un enlace de Google Drive
            if (url.includes('drive.google.com')) {
                const fileId = url.match(/\/(?:file|spreadsheets|document|presentation)\/d\/([^/]+)/)?.[1];
                if (fileId) {
                    downloadUrl = `https://drive.google.com/uc?id=${fileId}&export=download`;
                } else {
                    throw new Error('Google Drive URL no válida');
                }
            }
    
            // Verificar si es necesario realizar un fetch o solo simular la descarga
            if (downloadUrl.includes('drive.google.com/uc?')) {
                // Para Google Drive, crear un enlace directo
                const link = document.createElement('a');
                link.href = downloadUrl;
    
                // Nombre genérico si no se puede extraer del enlace
                const fileName = decodeURIComponent(
                    url.split('/').pop()?.split('?')[0] || 'download'
                );
                link.download = fileName;
    
                // Simular clic para descarga directa
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                // Simular un retraso para que el loader se desactive después del clic
                await new Promise(resolve => setTimeout(resolve, 500));
            } else {
                // Descargar el archivo con fetch para otros casos (como HubSpot)
                const response = await fetch(downloadUrl);
                if (!response.ok) {
                    throw new Error('Error al descargar el archivo');
                }
                const blob = await response.blob();
    
                // Crear un enlace temporal para la descarga
                const tempUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = tempUrl;
    
                // Extraer el nombre del archivo o usar uno genérico
                const fileName = decodeURIComponent(
                    url.split('/').pop()?.split('?')[0] || 'download'
                );
                link.download = fileName;
    
                // Simular clic y limpiar
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(tempUrl);
            }
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
            // Fallback: abrir en nueva pestaña si la descarga falla
            window.open(url, '_blank');
        } finally {
            // Reset loading state for this specific item
            setLoadingItems(prev => ({ ...prev, [index]: false }));
        }
    };
    

    return (
        <div className="grid-container">
            {data.map((item: LibraryResource, index: number) => (
                <a
                    className="text-decoration-none m-2"
                    href={item.url}
                    target="__blank"
                    rel="noopener"
                    key={`card-${index}`}
                >
                    <Card id={`card-${index}`}>
                        <Card.Title text={item.title} />
                        <Card.Subtitle
                            text={formatDate(item.createdAt)}
                            tag={
                                <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                    {isNew(item.createdAt, item.section) && (
                                        <Tag text="New" bgColor="#F0F0F2" />
                                    )}
                                    <Tag text={item.category} bgColor="#F0F0F2" />
                                </div>
                            }
                        />
                        <Card.Hero image={item.cover} />
                        {isDownloadableFile(item.url) && (
                            <div 
                                onClick={(e) => handleDownload(e, item.url, index)}
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    padding: '3px 5px 5px 3px',
                                    marginLeft: 'auto',
                                    marginTop: '16px',
                                    cursor: 'pointer',
                                    borderRadius: '2px',
                                    transition: 'background-color 0.2s ease',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'transparent'
                                }}
                                className="download-icon"
                            >
                                {loadingItems[index] ? (
                                    <div className="spinner" style={{
                                        width: '16px',
                                        height: '16px',
                                        border: '2px solid #706F7A',
                                        borderTop: '2px solid transparent',
                                        borderRadius: '50%',
                                        animation: 'spin 1s linear infinite'
                                    }} />
                                ) : (
                                    <span 
                                        className="icon-icon-download font-20px mx-1 cursor-pointer text-dark-60 text-dark-100-hover"
                                        style={{
                                            width: '16px',
                                            height: '16px'
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </Card>
                </a>
            ))}
            <style>
                {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                    .download-icon {
                        position: relative;
                    }
                `}
            </style>
        </div>
    );
}

const Card = ({ id, children }: { id: string, children: any }) => {
    return (
        <div id={id} className="card-item">
            {children}

        </div>
    )
}

Card.Title = Title
Card.Subtitle = Subtitle
Card.Hero = Hero

export default CardGrid;
