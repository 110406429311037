/* eslint-disable @typescript-eslint/no-empty-function */
type SessionState = {
    isExpired: boolean;
    isWarning: boolean;
};

type Listener = (state: SessionState) => void;

class SessionExpirationManager {
    private static instance: SessionExpirationManager;
    private listeners: Set<Listener> = new Set();
    private state: SessionState = {
        isExpired: false,
        isWarning: false
    };

    private constructor() {}

    static getInstance(): SessionExpirationManager {
        if (!SessionExpirationManager.instance) {
            SessionExpirationManager.instance = new SessionExpirationManager();
        }
        return SessionExpirationManager.instance;
    }

    addListener(listener: Listener) {
        this.listeners.add(listener);
        // Immediately notify new listener of current state
        listener(this.state);
    }

    removeListener(listener: Listener) {
        this.listeners.delete(listener);
    }

    setSessionExpired() {
        this.state = { isExpired: true, isWarning: false };
        this.notifyListeners();
    }

    setSessionWarning() {
        this.state = { isExpired: false, isWarning: true };
        this.notifyListeners();
    }

    resetSession() {
        this.state = { isExpired: false, isWarning: false };
        this.notifyListeners();
    }

    private notifyListeners() {
        this.listeners.forEach(listener => listener(this.state));
    }
}

export default SessionExpirationManager;
