import React, { useEffect, useState } from 'react';
import TableRow from 'UI/molecules/TableRow/index';
import { isEven } from 'utils/utils';
import { useTable } from 'contexts/tableContext';
import { useHistory } from 'react-router-dom';
import Favorites from 'services/storage/Favorites';
import { useTranslation } from 'react-i18next';
import eventEmitter from 'utils/eventEmiter';

const TableBody = ({ items }: { items: any[] }) => {
  const { t } = useTranslation();
  const router = useHistory();
  const { columnsList, enableBenchmarkData, sortMetric, selectedView, infiniteScrollLoading } =
    useTable();
  const striped = true;
  const [updateTable, setUpdateTable] = useState(false);

  const favoritesRoute = () =>
    router.location.search.includes('filter=favorites');

  const handleSort = React.useCallback(() => {
    let localItems = items;
    if (favoritesRoute()) {
      const currentFavorites = Favorites.getCurrentfavorites();
      console.log('Current favorites:', currentFavorites);
      const mergedFavorites = Favorites.mergeWithGlobalFavorites(currentFavorites);
      console.log('Merged favorites:', mergedFavorites);
      console.log('Items before filter:', items.length);
      localItems = items.filter((item) => mergedFavorites.includes(item.id));
      console.log('Items after filter:', localItems.length);
    }

    if (
      enableBenchmarkData &&
      sortMetric.length > 0 &&
      sortMetric[0].field
    ) {
      return localItems?.sort((a: any, b: any) =>
        sortMetric[0].order === 'DESC'
          ? b[`Savings_${sortMetric[0].field}_Relative`] -
          a[`Savings_${sortMetric[0].field}_Relative`]
          : a[`Savings_${sortMetric[0].field}_Relative`] -
          b[`Savings_${sortMetric[0].field}_Relative`]
      );
    }

    return localItems;
  }, [sortMetric, enableBenchmarkData, items, router.location, updateTable]);

  useEffect(() => {
    const listener = (payload: boolean) => {
      setUpdateTable(payload);
    };

    eventEmitter.on('updade_favorites', listener);

    // Cleanup listener on unmount
    return () => {
      eventEmitter.removeListener('updade_favorites', listener);
    };
  }, []);

  return (
    <tbody>
      {handleSort().length > 0
        ? handleSort().map((a: any, i: number) => (
          <TableRow
            key={`${a.id}-${i}`}
            indexRow={i}
            datas={a}
            subcolumns={columnsList}
            striped={isEven(i) && striped}
            enableBenchmarkData={enableBenchmarkData}
            {...{ selectedView }}
          />
        ))
        : favoritesRoute() && (
          <div
            className="font-12px"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {t('filter-favorites-empty')}
          </div>
        )}
      {
        infiniteScrollLoading && (
          <tr style={{ height: 'max-content' }}>
            <td colSpan={columnsList.length} className="text-center py-2">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </td>
          </tr>
        )
      }
    </tbody>
  );
};

export default TableBody;
