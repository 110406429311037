import React, { useState } from 'react';
import { ModalProjectInfo } from '../ModalProjectInfo';
import { useProjects } from 'contexts/projectsContext';
import { useTranslation } from 'react-i18next';
import { useTable } from 'contexts/tableContext';

interface Props{
	numberOfItems?: number;
}

function TableFooter({ numberOfItems }: Props) {
	const { t } = useTranslation();
	const { projectSelected } = useProjects();
	const { selectedView } = useTable();
	const [showProjectInfo, setShowProjectInfo] = useState<boolean>(false);

	const handleInfoDetails = () => {
		setShowProjectInfo(true);
	};

	const selectedViewName = selectedView?.name || 'items';

	const getDisplayName = (viewName: string) => {
		switch (viewName) {
			case 'articleList':
				return 'articles';
			case 'materialsList':
				return 'materials';
			case 'suppliersList':
				return 'suppliers';
			default:
				return 'items';
		}
	};

	const displayName = getDisplayName(selectedViewName);

	return (
		<>
			<div
				className="d-flex flex-row align-items-center justify-content-between px-4 py-3 position-absolute "
				style={{ borderTop: '1px solid #F0F0F2', bottom: 0, width: '100%', background: 'white', zIndex: 1 }}
			>
				<div className="d-flex flex-row justify-content-between">
					{numberOfItems ? `${numberOfItems} ` : ''}{displayName} |{' '}
					{projectSelected.version} version
				</div>

				{/* pagination */}
				<div
					className="font-14px d-flex flex-row justify-content-end align-items-center"
					style={{ minWidth: '300px' }}
				>
					<span
						className="cursor-pointer"
						onClick={handleInfoDetails}
					>
						Info
					</span>
				</div>
			</div>
			<ModalProjectInfo
				isShow={showProjectInfo}
				handleClose={() => setShowProjectInfo(false)}
				projectData={projectSelected}
			/>
		</>
	);
}

export default TableFooter;
