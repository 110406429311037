import React, { useState } from 'react';

// TAB ARTICLE DATA 

export const ApiResponse = () => {
	return (
		<>
			<Accordeon
				data={[
					{
						title: 'Article',
						content: (
							<AccordeonContent
								title="Essential references."
								elements={['Name', 'Style reference']}
							/>
						),
					},
					{
						title: 'PLM',
						content: (
							<AccordeonContent
								title="Stages in your value chain."
								elements={[
									'% Supplier traceability',
									'Nº processes',
									'Nº km from origin to warehouse',
									'Manufacturing supplier origin',
									'Article finishing supplier origin',
									'Material processing supplier origin',
								]}
							/>
						),
					},
					{
						title: 'LCA',
						content: (
							<AccordeonContent
								title="Essential environmental indicators."
								elements={[
									'Impact per indicator',
									'Benchmark per indicator',
									'Equivalences per indicator',
								]}
							/>
						),
					},
					{
						title: 'CEI',
						content: (
							<AccordeonContent
								title="Measures circularity of materials."
								elements={[
									'Material Circularity Indicator (MCI)',
									'Recycled content',
									'Total kg unrecoverable waste',
									'Utility',
								]}
							/>
						),
					},
					{
						title: 'Eco-Score',
						content: (
							<AccordeonContent
								title="Sustainability performance index."
								elements={[
									'Ecoscore by area',
									'Qualification by area',
								]}
							/>
						),
					},
					{
						title: 'AGEC',
						content: (
							<AccordeonContent
								title="Compliance standards."
								elements={[
									'Manufacturing supplier origin',
									'Article finishing supplier origin',
									'Material processing supplier origin',
									'Microplastic',
									'Recycled content',
									'Hazardous substances',
								]}
								infoMessage='Content available only in French.'
							/>
						),
					},
					{
						title: 'Others',
						content: (
							<AccordeonContent
								title="Additional indicator details."
								elements={[
									'Function unit',
									'Methodology version',
								]}
							/>
						),
					},
				]}
			/>
		</>
	);
};

export const AccountData = () => {
	return (
		<>
			<Accordeon
				data={[
					{
						title: 'AGEC packaging guide',
						content: (
							<AccordeonContent
								title="Compliance standards."
								elements={[
									'Manufacturing supplier origin',
									'Article finishing supplier origin',
									'Material processing supplier origin',
									'Microplastic',
									'Recycled content',
									'Hazardous substances',
								]}
								infoMessage='Content available only in French.'
							/>
						),
					}
				]}
			/>
		</>
	);
};

interface AccordeonContentProps {
	title: string;
	elements: string[];
	infoMessage?: string;
}

const AccordeonContent = ({ title, elements, infoMessage }: AccordeonContentProps) => {
	return (
		<div>
			<h3 className='font-14px text-dark-100 font-sfpro-regular'>{title}</h3>
			<ul className='font-14px text-dark-100 pl-3'>
				{elements.map((element, index) => (
					<li key={index}>{element}</li>
				))}
			</ul>
			{infoMessage &&
				<li className='d-flex align-items-center gap-2 ms-1'>
					<img src='/img/Group.svg' alt='Info Icon' style={{ marginRight: '8px' }} />
					<span>{infoMessage}</span>
				</li>
			}
		</div>
	);
};

const Accordeon = ({ data }: any) => {
	const [active, setActive] = useState(-1);

	const handleActive = (item: number) => {
		if (active === item) {
			setActive(-1);
			return;
		}

		setActive(item);
	};
	return (
		<section className="d-flex flex-column w-100 px-2">
			{data.map(({ title, content }: any, index: number) => (
				<div
					key={index}
					className="d-flex flex-column w-100 position-relative justify-content-start align-items-start"
					style={{
						height: 'max-content',
					}}
				>
					<header
						className="d-flex flex-row align-items-center w-100 py-3"
						onClick={() => handleActive(index)}
						style={{
							cursor: 'pointer',
						}}
					>
						<div className="d-flex flex-row justify-content-start align-items-center flex-grow-1">
							<h3 className="text-dark-100 font-14px m-0 font-sfpro-regular">
								{title}
							</h3>
						</div>
						<span
							className={`icon-icon-chevron-down font-30px hover-dark-100 ${active === index
								? 'text-dark-100'
								: 'text-dark-60'
								}`}
							style={{
								transition: 'all 0.5s ease',
								transformOrigin: '50% 50%',
								transform:
									active === index ? 'rotate(-180deg)' : '',
							}}
						/>
					</header>
					<div
						className="position-relative w-100"
						style={{ borderBottom: '.5px solid #D6D6D7' }}
					>
						<div
							className={`${active === index ? 'pb-3' : ''}`}
							style={{
								visibility:
									active === index ? 'visible' : 'hidden',
								height:
									active === index ? 'max-content' : '0px',
							}}
						>
							{content}
						</div>
					</div>
				</div>
			))}
		</section>
	);
};
