import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InputSelect from "UI/atoms/InputSelect/InputSelect";
import { IEcodesignItem, IEcodesignRequest } from "./types/ecodesign";
import { SimpleTooltip } from "UI/atoms/Tooltips/Index";
import { TooltipWrapper } from "UI/atoms/Tooltips/tooltip-wrapper";

interface IProps {
	request: IEcodesignRequest,
	setRequest: any,
    dataOptions: any,
    schema: IEcodesignItem,
    order: number,
    scenario: string, // itemA, itemB
    isSwitched: boolean,
}

interface ISelectorData {
    label: string,
    value: string,
    option: string,
}

const EcodesignCompare = (props: IProps) => {
	const { request, setRequest, dataOptions, schema, order, scenario } = props;
	const { t }: any = useTranslation();

    const itemBSelection = request.itemB;
    const itemASelection = request.itemA;

    const setItemBSelection = (itemB:any) => {
        setRequest((prev:any) => ({...prev, itemB: {...prev.itemB, ...itemB}}));
    }
    const setItemASelection = (itemA:any) => {
        setRequest((prev:any) => ({...prev, itemA: {...prev.itemA, ...itemA}}));
    }

    const handleOnChange = (e:any) => {
        if (scenario === 'itemA') {
            if (e.option === 'category') {
                setItemASelection({categoryId: e.value});
                setItemBSelection({categoryId: e.value});
            }
            if (e.option === 'process') {
                setItemASelection({processId: e.value});
            }
            if (e.option === 'origin') {
                setItemASelection({originId: e.value});
            }
        }
        if (scenario === 'itemB') {
            if (e.option === 'process') {
                setItemBSelection({processId: e.value});
            }
            if (e.option === 'origin') {
                setItemBSelection({originId: e.value});
            }
        }
    }

    return (
        <div className="mb-5">
            <h3 className="font-sfpro-medium font-14px text-dark-100">{t(`ecodesign-scenario-${order}-title`)}</h3>
			<h5 className="font-sfpro-regular font-14px text-dark-40 mb-4">{t(`ecodesign-scenario-${order}-description`)}</h5>

            <div className="d-flex flex-wrap">
                {schema && schema.options?.map((o:string) => {
                    const selectOptions: ISelectorData[] = [];
                    if (o === 'category' && scenario === 'itemA') {
                        dataOptions[o]?.map((d:any) => selectOptions.push({option: o, label: d.name, value: d.id}))
                    } else if (o === 'category' && scenario === 'itemB') {
                        dataOptions[o]?.map((d:any) => selectOptions.push({option: o, label: d.name, value: d.id}))
                    } else if (scenario === 'itemA') {
                        dataOptions.itemA[o]?.map((d:any) => selectOptions.push({option: o, label: d.name, value: d.id}))
                    } else if (scenario === 'itemB') {
                        dataOptions.itemB[o]?.map((d:any) => selectOptions.push({option: o, label: d.name, value: d.id}))
                    }

                    return (
                        <div key={`${o}-${scenario}`} className="mb-5 mr-3" style={{ width: '45%' }}>
                            <div className="d-flex flex-row align-items-center mb-2">
                                <p className="font-sfpro-medium font-14px text-dark-100 m-0">{t(`ecodesign-selector-${schema.label}-${o}`)}</p>
                                {
                                    (o === "category" && schema.group === 'Extraction') &&
                                    <>
                                        <TooltipWrapper
                                            tooltipText={t('ecodesign-material-category-extraction-tooltip')}
                                            styles={{
                                                maxWidth: '220px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            <span className="icon-icon-info font-14px text-dark-60 hover-dark-100 pl-2"></span>
                                        </TooltipWrapper>
                                    </>
                                }
                            </div>
                            {
                                scenario === 'itemA' && o === 'category' &&
                                <InputSelect
                                    placeholder={t('common-select')}
                                    values={selectOptions}
                                    valueSelected={itemASelection?.categoryId}
                                    onChange={(e:any) => handleOnChange(e)}
                                />
                            }
                            {scenario === 'itemA' && o === 'process' && <InputSelect
                                placeholder={t('common-select')}
                                values={selectOptions}
                                valueSelected={itemASelection?.processId}
                                onChange={(e:any) => handleOnChange(e)}
                                disabled={
                                    (request.stage !== 'T') &&
                                    (request.stage !== 'P') &&
                                    (!request.itemA.categoryId)
                                    ? true : false}
                            />}
                            {scenario === 'itemA' && o === 'origin' && <InputSelect
                                placeholder={t('common-select')}
                                values={selectOptions}
                                valueSelected={itemASelection?.originId}
                                onChange={(e:any) => handleOnChange(e)}
                                disabled={
                                    (!request.itemA.categoryId && !request.itemA.processId) ||
                                    (!request.itemA.processId)
                                    ? true : false}
                            />}

                            {scenario === 'itemB' && o === 'category' && <InputSelect
                                placeholder={t('common-select')}
                                values={selectOptions}
                                valueSelected={itemBSelection?.categoryId}
                                onChange={(e:any) => handleOnChange(e)}
                                disabled
                            />}
                            {scenario === 'itemB' && o === 'process' && <InputSelect
                                placeholder={t('common-select')}
                                values={selectOptions}
                                valueSelected={itemBSelection?.processId}
                                onChange={(e:any) => handleOnChange(e)}
                                disabled={
                                    (request.stage !== 'T') &&
                                    (request.stage !== 'P') &&
                                    (!request.itemB.categoryId)
                                    ? true : false}
                            />}
                            {scenario === 'itemB' && o === 'origin' && <InputSelect
                                placeholder={t('common-select')}
                                values={selectOptions}
                                valueSelected={itemBSelection?.originId}
                                onChange={(e:any) => handleOnChange(e)}
                                disabled={
                                    (!request.itemB.categoryId && !request.itemB.processId) ||
                                    (!request.itemB.processId)
                                    ? true : false}
                            />}

                            {scenario === 'itemA' && o === 'category' && !request.itemA.categoryId && <span className="text-dark-60 font-12px sfpro-regular ml-1">{t('common-field-required')}</span>}
                            {scenario === 'itemA' && o === 'process' && !request.itemA.processId && <span className="text-dark-60 font-12px sfpro-regular ml-1">{t('common-field-required')}</span>}
                            {scenario === 'itemA' && o === 'origin' && !request.itemA.originId && <span className="text-dark-60 font-12px sfpro-regular ml-1">{t('common-field-required')}</span>}
                            {scenario === 'itemB' && o === 'process' && !request.itemB.processId && <span className="text-dark-60 font-12px sfpro-regular ml-1">{t('common-field-required')}</span>}
                            {scenario === 'itemB' && o === 'origin' && !request.itemB.originId && <span className="text-dark-60 font-12px sfpro-regular ml-1">{t('common-field-required')}</span>}
                        </div>
                )})}
            </div>
        </div>
    )
}

export default EcodesignCompare;