import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardHttp from 'services/http/Dashboards';
import ChartContainer from 'UI/Template/ChartContainer';
import { useProjects } from 'contexts/projectsContext';
import SITable from './Charts/SITable';
import ScoreDistributionChart from './Charts/ScoreDistribution';
import AreasByPPTCChart from './Charts/AreasByPPTC';
import ProjectSummary from './Charts/ProjectSummary';
import RankingDashboard from './Charts/RankingDashboard';
import { InformationNavigation } from 'UI/molecules/InformationNavigation';
import { SpinnerLoader } from 'components/Common/Loader/Loader';
import { EMAIL_INFORMATION } from '../../../constants';
import TableFooter from '../TableFooter';

const IndexDashboard = () => {
	const { t } = useTranslation();
	const [emptyData, setEmptyData] = useState(false);
	const Dashboard = new DashboardHttp();
	const { projectSelected, setShowHeader } =
		useProjects();

	const [isLoading, setIsLoading] = useState(true);

	const [siData, setSIData]: any[] = useState();
	const [siScoreData, setSiScoreData]: any[] = useState();
	const [siScoreAreasPlanetData, setSiScoreAreasPlanetData]: any[] =
		useState();
	const [siScoreAreasPeopleData, setSiScoreAreasPeopleData]: any[] =
		useState();
	const [siScoreAreasTransData, setSiScoreAreasTransData]: any[] = useState();
	const [siScoreAreasCircData, setSiScoreAreasCircData]: any[] = useState();

	const [projectOrigins, setProjectOrigins]: any = useState();

	const getProjectOrigin = (projectId: string) => {
		const request = Dashboard.projectOrigins(projectId);
		return request.then((res) => res.data);
	};

	const getSITable = (projectId: string) => {
		const request = Dashboard.si(projectId);
		return request.then((res) => res.data);
	};

	const getScoreDistributionData = (projectId: string) => {
		const request = Dashboard.scoreDistributionIndex(projectId);
		return request.then((res) => res.data);
	};

	const getScoreByAreasPlanetData = (projectId: string) => {
		const request = Dashboard.scoreByAreaPPTC('Planet', projectId);
		return request.then((res) => res.data);
	};

	const getScoreByAreasPeopleData = (projectId: string) => {
		const request = Dashboard.scoreByAreaPPTC('People', projectId);
		return request.then((res) => res.data);
	};

	const getScoreByAreasTransData = (projectId: string) => {
		const request = Dashboard.scoreByAreaPPTC('Transparency', projectId);
		return request.then((res) => res.data);
	};

	const getScoreByAreasCircData = (projectId: string) => {
		const request = Dashboard.scoreByAreaPPTC('Circularity', projectId);
		return request.then((res) => res.data);
	};

	useEffect(() => {
		let isMounted = true;

		if (projectSelected?.id) {
			const id = projectSelected.id;
			Promise.all([
				getSITable(id),
				getScoreDistributionData(id),
				getScoreByAreasPlanetData(id),
				getScoreByAreasPeopleData(id),
				getScoreByAreasTransData(id),
				getScoreByAreasCircData(id),
				getProjectOrigin(id),
			])
				.then(
					([
						siTableResponse,
						scoreDistributionDataResponse,
						areasPlanetDataResponse,
						areasPeopleDataResponse,
						areasTransDataResponse,
						areasCircDataResponse,
						projectOriginsResponse,
					]) => {
						if (isMounted) {
							setSIData(siTableResponse);
							setSiScoreData(scoreDistributionDataResponse);
							setSiScoreAreasPlanetData(areasPlanetDataResponse);
							setSiScoreAreasPeopleData(areasPeopleDataResponse);
							setSiScoreAreasTransData(areasTransDataResponse);
							setSiScoreAreasCircData(areasCircDataResponse);
							setProjectOrigins(projectOriginsResponse);
							siTableResponse && setShowHeader(true);
							if (Object.values(siTableResponse).every((x: any) => x === null)) {
								setEmptyData(true);
							} else {
								setEmptyData(false);
							}
						}
					}
				)
				.catch((err) => console.log({ err }))
				.finally(() => setIsLoading(false));
		} else {
			setIsLoading(false);
		}

		return () => {
			isMounted = false;
		};
	}, [projectSelected]);

	const labelShortCopy = (value: string) => {
		return t(`dash-short-${value}`);
	};

	if (isLoading) {
		return (
			<div className="m-auto h-100 custom-spinner">
				<SpinnerLoader
					color={'#1C1C30'}
					width={'60px'}
					height={'60px'}
				/>
			</div>
		);
	}

	return (
		emptyData ? <InformationNavigation keyName={'upgrade'} email={EMAIL_INFORMATION} />
			: <>

				<div
					className="d-flex w-100 flex-column flex-grow-1 h-100 overflow-auto pb-2"
					style={{ marginTop: '-5px' }}
				>
					<div id="evaluation-dashboard">
						{/* Project summary section */}
						<div className="d-flex flex-wrap">
							<ProjectSummary />
						</div>

						<div className="d-flex flex-wrap">
							{/* Index Overview */}
							{siData && (
								<div
									className="col p-0 m-2 w-full"
									style={{ width: '100%' }}
								>
									<ChartContainer
										title={t('dash-title-SI')}
										subtitle={t('dash-subtitle-SI')}
									>
										<SITable data={siData} />
									</ChartContainer>
								</div>
							)}

							{/* Score distrution */}
							{siScoreData && (
								<div
									className="col p-0 m-2"
									style={{ minWidth: '640px' }}
								>
									<ChartContainer
										title={t('dash-title-scoreDistribution')}
										subtitle={t(
											'dash-subtitle-scoreDistribution'
										)}
									>
										<ScoreDistributionChart
											data={siScoreData}
											totalAmount={siScoreData.totalAmount}
											index={'planet'}
										/>
									</ChartContainer>
								</div>
							)}
						</div>

						{/* Area distribution by index */}
						<div className="d-flex w-100 flex-wrap justify-content-around">
							<div className="d-flex col flex-wrap px-0">
								{siScoreAreasPlanetData && (
									<div
										className="col p-0 m-2"
										style={{ minWidth: '300px' }}
									>
										<ChartContainer
											title={t('eco-score-dashboard-planet')}
										>
											<div
												className="d-flex overflow-auto justify-content-center align-items-center w-100 mx-auto"
												style={{ height: '200px' }}
											>
												<AreasByPPTCChart
													data={siScoreAreasPlanetData}
													color="#001D6C"
													labelShortCopy={labelShortCopy}
													unit={'%'}
												/>
											</div>
										</ChartContainer>
									</div>
								)}
								{siScoreAreasPeopleData && (
									<div
										className="col p-0 m-2"
										style={{ minWidth: '300px' }}
									>
										<ChartContainer
											title={t('eco-score-dashboard-people')}
										>
											<div
												className="d-flex overflow-auto justify-content-center align-items-center w-100 mx-auto"
												style={{ height: '200px' }}
											>
												<AreasByPPTCChart
													data={siScoreAreasPeopleData}
													color="#001D6C"
													labelShortCopy={labelShortCopy}
													unit={'%'}
												/>
											</div>
										</ChartContainer>
									</div>
								)}
							</div>

							<div className="d-flex col flex-wrap px-0">
								{siScoreAreasTransData && (
									<div
										className="col p-0 m-2"
										style={{ minWidth: '300px' }}
									>
										<ChartContainer
											title={t(
												'eco-score-dashboard-transparency'
											)}
										>
											<div
												className="d-flex overflow-auto justify-content-center align-items-center w-100 mx-auto"
												style={{ height: '200px' }}
											>
												<AreasByPPTCChart
													data={siScoreAreasTransData}
													color="#001D6C"
													labelShortCopy={labelShortCopy}
													unit={'%'}
												/>
											</div>
										</ChartContainer>
									</div>
								)}
								{siScoreAreasCircData && (
									<div
										className="col p-0 m-2"
										style={{ minWidth: '300px' }}
									>
										<ChartContainer
											title={t(
												'eco-score-dashboard-circularity'
											)}
										>
											<div
												className="d-flex overflow-auto justify-content-center align-items-center w-100 mx-auto"
												style={{ height: '200px' }}
											>
												<AreasByPPTCChart
													data={siScoreAreasCircData}
													color="#001D6C"
													labelShortCopy={labelShortCopy}
													unit={'%'}
												/>
											</div>
										</ChartContainer>
									</div>
								)}
							</div>
						</div>

						{/* Index best articles ranking */}
						<div className="d-flex flex-wrap">
							<ChartContainer
								title={t('dash-title-index-ranking')}
								subtitle={t('dash-subtitle-index-ranking')}
								ranking
								footer={t('dash-footer-ranking')}
							>
								<RankingDashboard />
							</ChartContainer>
						</div>
					</div>
					<TableFooter />
				</div>
			</>
	);
};

export default IndexDashboard;
