import React, { useEffect, useState } from 'react';
import { SelectedTag } from 'UI/molecules/FiltersSection/Index';
import { ScopeSelection } from 'UI/molecules/ScopeSelection/ScopeSelection';
import {
    REFERENCES_TYPE,
    SCOPES_BY_ARTICLE,
} from '../../../constants';
import { useProjects } from 'contexts/projectsContext';
import AppPaths from '../../../permissions/roles/AppPaths';

export default function NewFilterUI() {
    const { projectSelected, showHeader } = useProjects();
    const [referenceType, setReferenceType] = useState('');
    const [isLoading, setIsLoading] = useState(false); 
    const wPath = window.location.pathname;
    const generalProduct = wPath.split('/')[1];
    const product = wPath.split('/')[2];
    const scope = wPath.split('/')[3];
    const LIBRARY = 'library';

    useEffect(() => {
        if (projectSelected) {
            const copyReferenceType =
                projectSelected.referenceType?.toLowerCase() ?? '--';
            setReferenceType(copyReferenceType);
            if (
                product === 'plm' &&
                REFERENCES_TYPE.includes(copyReferenceType) &&
                scope === 'articles'
            ) {
                window.location.href = AppPaths.traceabilityMaterials;
            }
        }
    }, [projectSelected, product, scope]);

    const handleScopes = () => {
        if (product === 'plm' && REFERENCES_TYPE.includes(referenceType)) {
            if (SCOPES_BY_ARTICLE[product]?.includes('articles')) {
                return SCOPES_BY_ARTICLE[product].filter(
                    (item) => item !== 'articles'
                );
            }
        }
        return SCOPES_BY_ARTICLE[product] ?? null;
    };

    return (
        generalProduct !== LIBRARY && handleScopes() ? (
            <>
                <ScopeSelection
                    scopes={handleScopes()}
                    {...{
                        projectSelected,
                        isLoading,
                    }}
                />
                {['plm', 'lca', 'cei', 'sustainabilityindex'].includes(product) &&
                    scope === 'dashboard' && (
                        <SelectedTag
                            isLoading={isLoading}
                            wPath={wPath}
                            generalProduct={generalProduct}
                            product={product}
                        />
                    )}
            </>
        ) : null
    );
}
