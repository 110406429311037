import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import { calculateYAxis, formatNumberNoDecimals } from 'utils/utils';

const ComparationByIndustryChart = ({
    data,
    equivalences
}: any) => {
    const { t } = useTranslation();
    const [customData, setCustomData] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (!data) {
            console.warn('ComparationByIndustry: No data received');
            return;
        }

        // Mapeo de campos requeridos
        const requiredFields = {
            'Project_GW-R_A': 'Global Warming',
            'Project_Eutr-R_A': 'Eutrophication',
            'Project_AD-R_A': 'Abiotic Depletion',
            'Project_WS-R_A': 'Water Scarcity'
        };

        // Verificar campos requeridos
        const missingFields = [];
        for (const [field, name] of Object.entries(requiredFields)) {
            if (data[field] === undefined || data[field] === null) {
                missingFields.push(`${name} (${field})`);
            }
        }

        if (missingFields.length > 0) {
            console.warn('ComparationByIndustry: Missing required fields:', missingFields);
            console.log('Available data fields:', Object.keys(data));
            setCustomData([]);
            return;
        }

        const chartData = [
            {
                name: 'water-scarcity',
                data: parseFloat(data['Project_WS-R_A']) || 0,
                equivalence: 100,
            },
            {
                name: 'global-warming',
                data: parseFloat(data['Project_GW-R_A']) || 0,
                equivalence: 100,
            },
            {
                name: 'eutrophication',
                data: parseFloat(data['Project_Eutr-R_A']) || 0,
                equivalence: 100,
            },
            {
                name: 'abiotic-depletion',
                data: parseFloat(data['Project_AD-R_A']) || 0,
                equivalence: 100,
            }
        ];

        console.log('ComparationByIndustry: Chart data prepared:', chartData);
        setCustomData(chartData);
    }, [data])

    const NoDataToDisplay = () => (
        <div className="d-flex align-items-center text-dark-60 font-12px">
            There's no data to display
        </div>
    );

    const ItemFooter = ({color, text}: {color: string, text: string}) => (
        <div className='d-flex align-items-center justify mx-2 mr-3'>
            <div style={{
                width: 12,
                height: 12,
                borderRadius: "50%",
                background: color,
                marginRight: 4
            }}/>
            <span className='text-dark-60 font-12px'>{text}</span>
        </div>
    )

    const labelShortCopy = (value:string) => {
        return t(`dashboard-lca-graph-${value}`);
    }

    const customTooltip = (props: any) => {
        const { label, payload } = props;

        return (
            <div className="tooltip-dashboard">
                <div className="my-1 text-dark-100">{t(`dashboard-lca-graph-${label}`)}</div>
                {payload?.map((entry: any, index: number) => (
                    <div key={entry.name} style={{ color: '#696980' }}>
                        <span key={`item-${index}`} className="mr-1">
                            {t(`dashboard-lca-graph-tooltip-${entry.name}`)}:
                        </span>
                        <span>
                            {formatNumberNoDecimals(entry.value)}%
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            {customData.length > 0 ? (
                <div className="d-flex flex-row" style={{ width: '100%', height: '100%' }}>
                    <ResponsiveContainer width="100%" height={250}>
                        <BarChart data={customData}>
                            <CartesianGrid
                                vertical={false}
                                strokeDasharray="0"
                                stroke="#D6D6D7"
                            />
                            <XAxis
                                type="category"
                                dataKey="name"
                                angle={0}
                                tickFormatter={labelShortCopy}
                                height={40}
                                tickLine={{ stroke: 'transparent' }}
                                fontSize="12"
                                axisLine={false}
                                tick={{ fill: '#C6C7C8' }}
                            />
                            <YAxis
                                type="number"
                                fontSize="12"
                                allowDataOverflow={false}
                                axisLine={false}
                                tickLine={false}
                                tick={{ fill: '#C6C7C8' }}
                                unit="%"
                                domain={[0, calculateYAxis(customData, 'data')]}
                            />
                            <Tooltip content={customTooltip} />
                            <Bar
                                dataKey="data"
                                fill="#001D6C"
                                barSize={40}
                                radius={[2, 2, 2, 2]}
                                name="impact"
                            />
                            <Bar
                                dataKey="equivalence"
                                fill="#C9DBF8"
                                barSize={40}
                                radius={[2, 2, 2, 2]}
                                name="standard"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <NoDataToDisplay />
            )}
            <div
                className='w-100 d-flex justify-content-end align-items-center'
                style={{ borderTop: "1px solid #C6C7C8", height: 37 }}
            >
                <ItemFooter color="#001D6C" text={t('unitary-impact')} />
                <ItemFooter color="#C9DBF8" text={t('lca-dashbaord-industry-standard')} />
            </div>
        </>
    );
};

export default ComparationByIndustryChart;