/* eslint-disable @typescript-eslint/no-empty-function */
import Axios from "axios";

import StorageAuth from "./../services/storage/Auth";
import Auth from "./http/Auth";
import Notification from "../components/Elements/Notification";
import AppPaths from "permissions/roles/AppPaths";
import SessionExpirationManager from "./SessionExpirationManager";

const SAuth = new StorageAuth();
const auth = new Auth();

// Configuración de tiempos en milisegundos
const TOKEN_CONFIG = {
    // Intervalos de revisión según duración del token
    CHECK_INTERVALS: {
        SHORT_TERM: 30 * 60 * 1000,     // 30 minutos para tokens de 10 horas
        LONG_TERM: 6 * 60 * 60 * 1000,  // 6 horas para tokens de 30 días
    },
    // Tiempo de advertencia antes de expiración
    WARNING_TIME: 10 * 60 * 1000,       // 10 minutos antes de expirar
    // Duración considerada como largo plazo (30 días en ms)
    LONG_TERM_THRESHOLD: 30 * 24 * 60 * 60 * 1000
};

export class TokenManager {
    private static instance: TokenManager;
    private refreshTimeout: NodeJS.Timeout | null = null;
    private refreshCheckInterval: NodeJS.Timeout | null = null;
    private isRefreshing = false;
    private failedAttempts = 0;
    private MAX_RETRY_ATTEMPTS = 3;

    /** Private constructor to prevent direct instantiation as part of Singleton pattern */
    private constructor() { }

    static getInstance(): TokenManager {
        if (!TokenManager.instance) {
            TokenManager.instance = new TokenManager();
        }
        return TokenManager.instance;
    }

    // Función para decodificar el token JWT
    private decodeToken(token: string): any {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(window.atob(base64));
        } catch (error) {
            return null;
        }
    }

    // Calcula el tiempo hasta la expiración del token en milisegundos
    private getTimeUntilExpiration(token: string): number {
        const decodedToken = this.decodeToken(token);
        if (!decodedToken) return 0;
        const expirationTime = decodedToken.exp * 1000;
        return expirationTime - Date.now();
    }

    // Programa la próxima renovación del token
    private scheduleTokenRefresh(token: string) {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }

        const timeUntilExp = this.getTimeUntilExpiration(token);
        if (timeUntilExp <= 0) {
            this.refreshToken();
            return;
        }

        // Programar la renovación 5 minutos antes de la expiración
        const refreshDelay = Math.max(0, timeUntilExp - 5 * 60 * 1000);
        this.refreshTimeout = setTimeout(() => this.refreshToken(), refreshDelay);
    }

    // Renueva el token
    private async refreshToken() {
        if (this.isRefreshing) return;

        const session = SAuth.getSession();
        if (!session.accessToken || !session.refreshToken) {
            this.stopTokenRefresh();
            return;
        }

        this.isRefreshing = true;

        try {
            const refreshedSession = await auth.refreshToken(session.refreshToken);
            this.failedAttempts = 0;
            
            SAuth.saveSession({
                accessToken: refreshedSession.accessToken,
                refreshToken: session.refreshToken,
                rememberMe: session.rememberMe || false,
            });

            // Programar la próxima renovación
            this.scheduleTokenRefresh(refreshedSession.accessToken);
        } catch (error) {
            this.failedAttempts++;
            
            if (this.failedAttempts >= this.MAX_RETRY_ATTEMPTS) {
                Notification.display(
                    "error",
                    "Unable to maintain your session. Please log in again."
                );
                this.logout();
            } else {
                const retryDelay = 30000;
                setTimeout(() => this.refreshToken(), retryDelay);
                if (this.failedAttempts > 1) {
                    Notification.display(
                        "warning",
                        "Having trouble maintaining your session. Retrying..."
                    );
                }
            }
        } finally {
            this.isRefreshing = false;
        }
    }

    // Iniciar el manejo de tokens
    startTokenRefresh() {
        const session = SAuth.getSession();
        if (session.accessToken) {
            this.scheduleTokenRefresh(session.accessToken);
            this.startRefreshTokenCheck();
        }
    }

    private getCheckInterval(token: string): number {
        const timeUntilExp = this.getTimeUntilExpiration(token);
        return timeUntilExp > TOKEN_CONFIG.LONG_TERM_THRESHOLD
            ? TOKEN_CONFIG.CHECK_INTERVALS.LONG_TERM
            : TOKEN_CONFIG.CHECK_INTERVALS.SHORT_TERM;
    }

    private startRefreshTokenCheck() {
        if (this.refreshCheckInterval) {
            clearInterval(this.refreshCheckInterval);
        }

        const session = SAuth.getSession();
        if (!session.refreshToken) return;

        const checkInterval = this.getCheckInterval(session.refreshToken);
        this.checkRefreshToken();
        this.refreshCheckInterval = setInterval(() => {
            this.checkRefreshToken();
        }, checkInterval);
    }

    private checkRefreshToken() {
        const session = SAuth.getSession();
        if (session.refreshToken) {
            const refreshTokenExpTime = this.getTimeUntilExpiration(session.refreshToken);
            const warningTime = TOKEN_CONFIG.WARNING_TIME;

            if (refreshTokenExpTime <= warningTime) {
                const sessionManager = SessionExpirationManager.getInstance();
                sessionManager.setSessionExpired();
                if (this.refreshCheckInterval) {
                    clearInterval(this.refreshCheckInterval);
                }
            }
        }
    }

    // Detener el manejo de tokens
    stopTokenRefresh() {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
            this.refreshTimeout = null;
        }
        if (this.refreshCheckInterval) {
            clearInterval(this.refreshCheckInterval);
            this.refreshCheckInterval = null;
        }
        this.isRefreshing = false;
        this.failedAttempts = 0;
    }

    // Función de logout
    private logout() {
        this.stopTokenRefresh();
        SAuth.removeSession();
        window.location.href = AppPaths.login;
    }
}

// Instancia del TokenManager
export const tokenManager = TokenManager.getInstance();

// Configurar interceptores de Axios
const setupAxiosInterceptors = () => {
    Axios.interceptors.request.use(async (config) => {
        const token = SAuth.getSession().accessToken;
        if (token) {
            let t = token;
            if (t.startsWith('Bearer') == false) {
                t = `Bearer ${token}`
            }
            config.headers && (config.headers.Authorization = `${t}`);
        }
        return config;
    });

    Axios.interceptors.response.use(undefined, async (error) => {
        if (error.response?.data?.statusCode === 403) {
            const Session = SAuth.getSession();

            if (!Session.refreshToken || error.response.data.path === "/api/auth/refresh") {
                tokenManager.stopTokenRefresh(); // Detener el manejo de tokens
                SAuth.removeSession();
                Notification.display(
                    "warning",
                    "Your session has expired. Please log in again."
                );
                window.location.href = AppPaths.login;
                throw new Axios.Cancel();
            }

            try {
                const refreshedSession = await auth.refreshToken(Session.refreshToken);
                SAuth.saveSession({
                    accessToken: refreshedSession.accessToken,
                    refreshToken: Session.refreshToken,
                    rememberMe: Session.rememberMe || false,
                });
                tokenManager.startTokenRefresh(); // Reiniciar el manejo de tokens
                return Axios.request(error.config);
            } catch (err) {
                tokenManager.stopTokenRefresh(); // Detener el manejo de tokens
                SAuth.removeSession();
                Notification.display(
                    "error",
                    "Could not update token. Please log in again."
                );
                window.location.href = AppPaths.login;
                throw new Axios.Cancel();
            }
        }

        throw error;
    });
};

export default () => {
    setupAxiosInterceptors();
    tokenManager.startTokenRefresh();
};