import React, { useEffect, useRef, useState } from "react";

import { useProjects } from "contexts/projectsContext";
import { ResTable, useTable } from "contexts/tableContext";
import FilterHttp from 'services/http/Filters';
import { ICrudListResponse } from "services/http/Crud";
import Project from "services/http/Project";

import { InformationNavigation } from "UI/molecules/InformationNavigation";
import { EMetricNames } from "UI/Template/Table/types/TableEnums";
import { EMAIL_INFORMATION } from "../constants";

export type ScopeType = 'materials' | 'suppliers' | 'articles' | 'cei' | 'projects';

const validateDataFields = [EMetricNames.MCI_A, EMetricNames.Recycled_A, EMetricNames.TU_Waste_A, EMetricNames.Utility_A]

const Filters = new FilterHttp();
const Projects = new Project();

function fetchData (scope: ScopeType, projectId: string, dataQuery: any): Promise<ICrudListResponse> {
    if (scope === 'materials') return Filters.filterMaterials(projectId, dataQuery);
    if (scope === 'suppliers') return Filters.filterSuppliers(projectId, dataQuery);
    if (scope === 'articles') return Filters.filterArticles(projectId, dataQuery);
    if (scope === 'projects') return Projects.allProjects('&sort=createdAt,DESC');
    if (scope === 'cei') return Filters.filterCei(projectId, dataQuery);

    return Promise.reject('Invalid scope');
}

function defineScope (path: string): ScopeType {
    if (path.includes('/plm/materials')) return 'materials';
    if (path.includes('/plm/suppliers')) return 'suppliers';
    if (path.includes('/data-collection')) return 'projects';
    if (path.includes('/cei/')) return 'cei';
    return 'articles';

}

export const tableLoader = (Component: any) => {
    return (props: any) => {
        const scope = useRef<ScopeType>('articles');
        const source = Filters.getSource();
        const mountedRef = useRef(true);
        const { projectSelected, setShowHeader } = useProjects();
        const [noData, setNoData] = useState(false);
        const {
            currentPage,
            limitPagination,
            searchText,
            sortMetric,
            filtersApplied,
            selectedView,
            isLoading,
            handleLoading,
            setRes,
            res,
            setInfiniteScrollLoading,
        } = useTable();

        const safeSetState = (callback: () => void) => {
            if (mountedRef.current) {
                callback();
            }
        };

        const getInitData = (scope: ScopeType) => {
            if (!mountedRef.current || !projectSelected) return;

            const dataQuery = {
                currentPage: currentPage,
                limitPagination: limitPagination,
                search: searchText,
                sortMetric: sortMetric,
                filters: filtersApplied
            }

            safeSetState(() => handleLoading(true));

            const request = fetchData(scope, projectSelected.id, dataQuery);

            request.then((response) => {
                if (!mountedRef.current) return;

                let data: ResTable['datas'] | null = null;
                if (scope === 'cei') {
                    data = response.data['articles'];
                } else if (scope === 'projects') {
                    data = response.data;
                } else {
                    data = response.data[scope];
                }

                safeSetState(() => {
                    setNoData(data?.length === 0);
                    setRes({
                        datas: data || [],
                        pageCount: response.data.pageCount || 0,
                        total: response.data.total || 0,
                    });
                    setShowHeader(true);
                });
            })
            .catch((err) => {
                if (mountedRef.current) {
                    console.error('Error in getInitData:', err);
                    safeSetState(() => {
                        setNoData(true);
                        setRes({
                            datas: [],
                            pageCount: 0,
                            total: 0,
                        });
                    });
                }
            })
            .finally(() => {
                if (mountedRef.current) {
                    handleLoading(false);
                }
            });
        };

        const getData = (scope: ScopeType) => {
            if (!mountedRef.current || !projectSelected) return;

            const dataQuery = {
                currentPage: currentPage,
                limitPagination: limitPagination,
                search: searchText,
                sortMetric: sortMetric,
                filters: filtersApplied
            }

            safeSetState(() => setInfiniteScrollLoading(true));

            const request = fetchData(scope, projectSelected.id, dataQuery);

            request.then((response) => {
                if (!mountedRef.current) return;

                let data: ResTable['datas'] | null = null;
                if (scope === 'cei') {
                    data = response.data['articles'];
                } else if (scope === 'projects') {
                    data = response.data;
                } else {
                    data = response.data[scope];
                }

                safeSetState(() => {
                    setNoData(data?.length === 0);
                    setRes((prevRes: ResTable) => ({
                        datas: [...prevRes.datas, ...(data || [])],
                        pageCount: response.data.pageCount || prevRes.pageCount,
                        total: response.data.total || prevRes.total,
                    }));
                    setShowHeader(true);
                });
            })
            .catch((err) => {
                if (mountedRef.current) {
                    console.error('Error in getData:', err);
                }
            })
            .finally(() => {
                if (mountedRef.current) {
                    setInfiniteScrollLoading(false);
                }
            });
        };

        useEffect(() => {
            if (projectSelected) {
                const wPath = window.location.pathname;
                scope.current = defineScope(wPath);
                getInitData(scope.current);
            }
        }, [projectSelected]);

        useEffect(() => {
            if (!projectSelected) return;

            const wPath = window.location.pathname;
            scope.current = defineScope(wPath);

            if (res.pageCount >= currentPage && res.datas.length < res.total) {
                getData(scope.current);
            }

            return () => source.cancel();
        }, [filtersApplied, currentPage, limitPagination, sortMetric, selectedView]);

        useEffect(() => {
            if (!projectSelected) return;

            const wPath = window.location.pathname;
            const currentScope = defineScope(wPath);
            let timeoutId: NodeJS.Timeout;

            if (searchText) {
                timeoutId = setTimeout(() => {
                    if (mountedRef.current) {
                        getData(currentScope);
                    }
                }, 300);
            }

            return () => {
                clearTimeout(timeoutId);
                source.cancel();
            };
        }, [searchText]);

        useEffect(() => {
            return () => {
                mountedRef.current = false;
                source.cancel();
            };
        }, []);

        const validateData = () => {
            if (scope.current === 'cei') {
                let count = 0;
                validateDataFields.forEach(item => {
                    if (res.datas[0] && (res.datas[0][item] === null || res.datas[0][item] === 0)) {
                        count++;
                    }
                });
                
                setShowHeader(true);
                return count === validateDataFields.length;
            }
            return false;
        };

        return validateData()
            ? <InformationNavigation keyName={scope.current} />
            : <Component {...props} items={res.datas} isLoading={isLoading} />
    }
}