/* eslint-disable */
import React, { Component, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import InfiniteList from 'components/Common/InfiniteList/InfiniteList';
import TablePagintation from 'components/Table/components/TablePagination';

import { isEven, objToQueryString, queryStringToObj } from 'utils/utils';

import './PageList.scss';
//Mock

import { ETableTypes, ITableSchema } from 'components/Table/types/TableTypes';
import ErrorException from 'services/exception/ErrorException';
import Crud from 'services/http/Crud';
import Http from 'services/http/Http';
import { InformationNavigation } from 'UI/molecules/InformationNavigation';
import Notification from '../../components/Elements/Notification';

export interface Props {
	// [key: string]: any | any[];
	initialFilter? : Object | any, // Filtro inicial de la tabla
	entityName: string, // Nombre de la entity de la tabla: ej: articles, projects, etc
	schema: ITableSchema, // Schema de la entity para generar las columnas de las tablas: src/components/Tables/schemas
	entityHttp?: Http | Crud | any, // Object Http (services/http) que se usa para automatizar las peticiones "list"; Si no se quiere usar el método "list" usar la funcion onGetItems

	contentWrapperClass?: string, // Clases CSS para el content wrapper;

	// Components;
	EntityFilter: Component | any, // Componente de los filtros de la tabla; Los btn que hay arriba; usar por defecto src/components/Table/components/TableHeader
	ListHeader: Component | any, // Header de la tabla;  usar por defecto components/Table/components/TableFilters
	ListItem: Component | any // Componente de una row de la tabla; usar por defecto components/Table/components/TableItem
	modalCreate?: Component | any //Componente modal de cuando das click al btn add;

	// Functions
	onClickListItem?(item: any): any, // Al hacer click sobre una row;
	onHoverListItem?(item: any): any, // Al pasar cursor sobre una row;
	onMouseLeaveListItem?(item: any): any,
	onDelete?(items: any[]): any, // Al hacer click al btn delete;
	onEditMultiple?(items: any[]): any, // Al hacer click btn editar multiple;
	onGetItems?(values: Record<string, unknown>, pagination: Record<string, unknown>, concat: boolean, forceUpdate: boolean): Promise<any>,
}

const PageList = (props: Props) => {
	const {
		entityName,
		modalCreate,
		schema,

		contentWrapperClass = "",

		// Components
		EntityFilter,
		ListItem,
		onClickListItem,
		onHoverListItem,
		onMouseLeaveListItem,
		ListHeader,

		entityHttp,
		onGetItems,
		initialFilter,
	} = props;

	const {
		//table type
		tableType = ETableTypes.pagination,

		itemsStriped = false,
		paginationLimit = 50
	} = schema;

	const [items, setItems]: any = useState([]);
	const [total, setTotal] = useState(0);
	const [totalFound, setTotalFound] = useState(0);
	const [loading, setLoading] = useState(true);
	const [isDownloading, setIsDownloading] = useState(false);
	const [filter, setFilter] = useState({ ...initialFilter });
	const [pagination, setPagination] = useState({
		skip: 0,
		limit: paginationLimit
	});
	const [selectionMode, setSelectionMode] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const { t } = useTranslation();
	const [urlFilters, setUrlFilters] = useState({});
	const history = useHistory();

	const onCreate = () => {
		// Get filters from querystring
		const urlParams = history.location.search.replace('?', '');
		const newFilters = {
			...initialFilter,
			...queryStringToObj(urlParams)
		};
		setFilter(newFilters);
		setUrlFilters(newFilters);
		getItems(newFilters, pagination, false, true);

	}

	useEffect(() => {
		setItems([]);
		onCreate();
	}, [onGetItems]);

	useEffect(() => {
		setSelectedItems([]);
	}, [schema, entityName])

	async function getItems(values: Record<string, unknown>, pagination: Record<string, unknown>, concat: boolean = false, forceUpdate: boolean = false) {
		let response;
		setLoading(true);
		try {
			if (entityHttp) {
				response = await entityHttp.list(values, pagination);
			}
			else if (onGetItems) {
				response = await onGetItems(values, pagination, concat, forceUpdate);
			}

			if (response) {
				let results = [];
				let total = 0;
				if (response.data.length) {
					results = response.data;
					total = response.data.length;
				}
				else {
					results = !response.data || response.data === '' ? [] : response.data;
					total = response.data.length;
				}

				const itemsFound = concat ? items.concat(results) : results;
				setTotal(total);
				setTotalFound(itemsFound.length);
				setItems(itemsFound);
			}
		}
		catch (err) {
			const error = await new ErrorException(err instanceof Error ? err.message : String(err));
			Notification.displayException(error);
		}
		finally {
			setLoading(false);
		}
	}

	async function fetchMoreData() {
		const newPagination = {
			...pagination,
			skip: pagination.skip + pagination.limit
		};

		setPagination(newPagination);
		getItems(filter, newPagination, true, true);
	}

	async function refreshResults(filter: any) {
		setLoading(true);
		await getItems(filter, { ...pagination, skip: 0 }, false, true);
		setLoading(false);
	}

	async function handleFilterChange(key: any, value: any, clear: string[] = []) {
		let newFilter: any = {};
		if (typeof key === 'string') {
			newFilter = {
				...filter,
				[key]: value && value.target ? value.target.value : value
			};
		} else if (typeof key === 'object') {
			let obj: any = {};
			for (let i = 0; i < key.length; i++) {
				obj[key[i]] = value[key[i]];
			}
			newFilter = {
				...filter,
				...obj
			};
		}
		if (clear.length > 0) {
			for (let i = 0; i < clear.length; i++) {
				newFilter[clear[i]] = null;
			}
		}
		setFilter(newFilter);
		refreshResults(newFilter);

		// Add the filters to the query params
		const url = `${history.location.pathname}?${objToQueryString(newFilter)}`;
		window.history.pushState({}, '', url);
	}


	const handleDownload = () => {
		// if (!isDownloading) {
			// 	setIsDownloading(true);
			// 	entityHTTP
			// 		.exportData(filter)
			// 		.then((res: any) => {
				// 			window.location = res.url;
				// 			setIsDownloading(false);
				// 		})
				// 		.catch((error: any) => Notification.displayException(error));
				// }
			};


	let list = <></>
	if (tableType == ETableTypes.pagination) {
		list = (
			<>
				{items.map((item: any, index: number) => (
					<ListItem
						key={item.id}
						{...props}
						item={item}
						selectionMode={selectionMode}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						onClick={onClickListItem}
						onHover={onHoverListItem}
						onLeave={onMouseLeaveListItem}
						itemsStriped={itemsStriped == true && isEven(index)}
					/>
				))}
				<div className="d-flex flex-row justify-content-end mt-3">
					<TablePagintation {...props} />
				</div>
			</>
		)
	}
	else if (tableType == ETableTypes.infinite) {
		list = (
			<div style={{height: schema.height, overflowY: 'auto', overflowX: 'hidden'}} id="scroll-list-wrapper">
				<InfiniteList
						{...props}

						items={items}
						filter={filter}
						isLoading={loading}
						total={total}
						totalFound={totalFound}
						fetchMoreData={fetchMoreData}
						striped={itemsStriped}
						// List item
						ListItem={ListItem}

						//Selection
						selectionMode={selectionMode}
						setSelectionMode={setSelectionMode}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
					/>
				</div>
		)
	}

	const pathProjects = history.location.pathname;

	return (
		<div id={'page'} className={`content-wrapper ${contentWrapperClass}`}>
			{/* Header */}
			{pathProjects !== '/projects' && pathProjects !== '/digitization' &&
			<ListHeader
				{...props}
				items={items}
				//Filters
				filter={filter}
				handleFilterChange={handleFilterChange}
				//Selection
				selectionMode={selectionMode}
				setSelectionMode={setSelectionMode}
				selectedItems={selectedItems}
				setSelectedItems={setSelectedItems}
			/>}

			{
				(pathProjects === "/" && items?.length == 0) ?
				<InformationNavigation keyName='project-list' removeClasses={true} /> :
				<>
					{/* Filter */}
					<div className={'filter'}>
						<div className="filter-inputs">
							<EntityFilter
								{...props}

								filter={filter}
								items={items}
								totalFound={totalFound}
								//Filters
								handleFilterChange={handleFilterChange}
								//Selection
								selectionMode={selectionMode}
								setSelectionMode={setSelectionMode}
								selectedItems={selectedItems}
								setSelectedItems={setSelectedItems}
							/>
						</div>
					</div>

					{/* List */}
					<div>
						{list}
					</div>
				</>
			}
		</div>
	);
};
export default PageList;
