import React, { ReactElement, useEffect, useState } from 'react';

import { useProjects } from 'contexts/projectsContext';
import ChartContainer from 'UI/Template/ChartContainer';
import { numberWithDots, formatNumberNoDecimals } from 'utils/utils';
import { PieChartDashboard } from 'UI/organism/Dashboards/Charts/PieChartDashboard';

import ProgressBarSimple from 'UI/atoms/ProgressBarSimple/Index';
import { Table, HeaderTable } from 'UI/organism/Dashboards/Charts/Ranking';
import { TooltipTraceabilityScore } from 'UI/atoms/Tooltips/Index';
import {useTraceabilityDashboard} from 'hooks/useTraceabilityDashboard.hook';
import { SpinnerLoader } from 'components/Common/Loader/Loader';

import { TContent, TStaticValues, SUMMARY_CARDS_KEYS } from 'types/TTraceabilityDashboard';
import { useReferenceType } from 'hooks/useReferenceType.hook';
import { EMAIL_INFORMATION } from '../../../constants';
import { InformationNavigation } from 'UI/molecules/InformationNavigation';
import TableFooter from 'UI/organism/TableFooter';

const TABLE_GROUP = 'Traceability_total_suppliers_traced';

const TraceabilityDashboard = (): ReactElement => {
	const { projectSelected } = useProjects();
	const { validateReferenceType } = useReferenceType(projectSelected);
	const [emptyValue, setEmtyValue] = useState(true);

	const {
		summaryCards,
		completion,
		transportDistribution,
		rawMaterialComposition,
		manufacturingCountries,
		materialCountries,
		traceabilityRanking,
		rawMaterialCharacteristics,
		detectEmptyObject,
		t,
		SUMMARY_TITLES,
		loading,
		toggleCompletionTooltips,
		summaryKeys
	} = useTraceabilityDashboard();

	const cardStyles = { minWidth: '500px', height: '313px' };

	const { setShowHeader } = useProjects();

	if (validateReferenceType) {
		const removedIndex = summaryKeys.indexOf('project_articles');
		summaryKeys.splice(removedIndex, 1);
		SUMMARY_TITLES.splice(removedIndex, 1);
	}

	useEffect(() => {
		setShowHeader(true);
		() => {
			setShowHeader(false);
		}
	}, []);

	useEffect(() => {
		if (
			rawMaterialComposition.length === 0 &&
			transportDistribution.length === 0 &&
			manufacturingCountries.project_article_supplier_manufacturing_origins.length === 0
		) {
			setEmtyValue(true);
		} else {
			setEmtyValue(false)
		}
	}, [rawMaterialComposition, transportDistribution, manufacturingCountries])

	const NoDataToDisplay = () => (
		<div
			className="d-flex align-items-center justify-content-center text-dark-60 font-12px"
			style={{ height: ' 250px' }}
		>
			There's no data to display
		</div>
	);

	const validateProjectVersion = () => parseFloat(projectSelected?.version ?? '0') > 1.3

	if (loading) {
		return <div className="m-auto h-100 custom-spinner">
			<SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"}/>
		</div>
	}

	return (
		emptyValue ? <InformationNavigation keyName="upgrade" email={EMAIL_INFORMATION} /> :
		<section
			className={`h-100 overflow-auto mb-5 pb-2 ${
				loading ? 'd-flex' : ''
			}`}
		>
			{loading ? (
				<div className="m-auto">
					<SpinnerLoader
						color={'#1C1C30'}
						width={'80px'}
						height={'80px'}
					/>
				</div>
			) : (
				<div id='traceability-dashboard'>
					<div className="d-flex flex-wrap">
						{(summaryKeys as Array<SUMMARY_CARDS_KEYS>).map((key, index: number) => (
							<div
								key={SUMMARY_TITLES[index]}
								className="col p-0 m-2"
								style={{ minWidth: '200px', height: '160px' }}
							>
								<ChartContainer title={SUMMARY_TITLES[index]}>
									{(summaryCards[key] !== '--' && validateProjectVersion()) ? (
										<div
											className="d-flex mx-auto font-sfpro-regular text-black-100"
											style={{ fontSize: '40px' }}
										>
											{numberWithDots(
												formatNumberNoDecimals(
													key === 'project_distance'
													? summaryCards[key]
													: parseFloat(summaryCards[key])
												)
											)}
										</div>
									) : (
										<NoDataToDisplay />
									)}
								</ChartContainer>
							</div>
						))}
					</div>

					<div className="d-flex flex-wrap position-relative pb-4">
						<div
							className="d-flex col p-0 m-2 w-100"
							style={cardStyles}
						>
							<div className="col p-0 mr-2 d-flex flex-column">
								<ChartContainer
									title={t('completion')}
									subtitle={t('completionTraceability')}
									footer={
										(typeof completion.original[1]?.value !== 'number' && !completion.original[1].value) ? (
											''
										) : (
											<FooterCard
												list={completion.original}
												{...{ t }}
											/>
										)
									}
									padding={0}
									traceability
								>
									{((typeof completion.original[1].value !== 'number' && !completion.original[1].value) || !validateProjectVersion())  ? (
										<NoDataToDisplay />
									) : (
										<div
											className="d-flex flex-column w-100 align-items-center"
											style={{ zIndex: 1 }}
										>
											<PieChartDashboard
												tooltip
												centerInformation={
													<span
														className="position-absolute text-dark-100"
														style={{
															top: '50%',
															left: '50%',
															transform:
																'translate(-50%,-50%)',
															fontSize: '24px',
														}}
													>
														<div className="d-flex flex-column align-items-center">
															<span className="text-uppercase font-12px text-dark-60">
																{completion.tooltips ? completion.tooltips[
																	'project_traceability_score'
																] : '--'}
															</span>
															<span className="font-24px text-dark-100 font-sfpro-medium">
																{
																	completion.tooltips ? completion.tooltips[
																		'project_traceability_total_suppliers_traced'
																	] : '0'
																}
																%
															</span>
														</div>
														<TooltipTraceabilityScore
															top={40}
															left={-50}
														>
															<div
																className="d-flex flex-row"
																role="traceability-score-tooltip"
															>
																<div
																	className="mt-1"
																	style={{
																		width: '15%'
																	}}
																>
																	<div
																		style={{
																			width:
																				'12px',
																			height:
																				'12px',
																			backgroundColor:
																				'#497ACF',
																			borderRadius:
																				'50%',
																		}}
																	></div>
																</div>
																<div className="d-flex flex-column w-100">
																	<p
																		style={{
																			marginBottom: 5,
																		}}
																	>
																		<strong>{`${formatNumberNoDecimals(
																			toggleCompletionTooltips('project_traceability_total_suppliers_traced', 0)
																		)}% ${t(
																			'supplier-know'
																		)}`}</strong>
																	</p>
																	{
																		validateReferenceType ? null : (
																			<p
																				style={{
																					marginBottom: 5,
																				}}
																			>{`${formatNumberNoDecimals(
																				toggleCompletionTooltips('project_traceability_article_suppliers_traced', 0)
																			)}% ${t(
																				'article-supplier'
																			)}`}</p>
																		)
																	}
																	<p
																		style={{
																			marginBottom: 5,
																		}}
																	>{`${formatNumberNoDecimals(
																		toggleCompletionTooltips('project_traceability_mat_suppliers_traced', 0)
																	)}% ${t(
																		'material-supplier'
																	)}`}</p>
																	<p
																		style={{
																			marginBottom: 5,
																		}}
																	>{`${formatNumberNoDecimals(
																		toggleCompletionTooltips('project_traceability_rmat_suppliers_traced', 0)
																	)}% ${t(
																		'raw-material-supplier'
																	)}`}</p>
																</div>
															</div>
														</TooltipTraceabilityScore>
													</span>
												}
												datas={completion.original}
												values={completion.original.map((item: TStaticValues) => item.value)}
												customStyle={{ margin: 'auto' }}
											/>
										</div>
									)}
								</ChartContainer>
							</div>
							{
								validateReferenceType ? (
									<div className="col p-0 ml-2">
										<ChartContainer
											title={t('raw-material-country')}
											between
										>
											{(detectEmptyObject(materialCountries) || !validateProjectVersion()) ? (
												<NoDataToDisplay />
											) : (
												<div
													className="d-flex flex-column w-100 align-items-center overflow-auto pr-2"
													style={{ gap: '15px' }}
												>
													{materialCountries[
														'project_article_process_matProcessing_supplier_origin'
													]?.map(
														(
															country: string,
															index: number
														) => (
															<div
																key={country}
																className="d-flex flex-column w-100"
																style={{ gap: '5px' }}
															>
																<div className="d-flex flex-row justify-content-between align-items-center font-12px">
																	<span className="text-dark-100">
																		{country}
																	</span>
																	<span className="text-dark-60">
																		{materialCountries[
																				'project_article_process_matProcessing_supplier_origin_shares'
																			][index]
																		}
																		%
																	</span>
																</div>
																<ProgressBarSimple
																	tooltip={false}
																	color="black"
																	percent={
																		parseFloat(materialCountries.project_article_process_matProcessing_supplier_origin_shares[index])
																	}
																	customStyles={{
																		width:
																			'100% !important',
																	}}
																/>
															</div>
														)
													)}
												</div>
											)}
										</ChartContainer>
									</div>
								) : (
									<div className="col p-0 ml-2">
										<ChartContainer
											title={t('transport-used')}
											subtitle={t('warehouse-manufacturing')}
											footer={
												detectEmptyObject(
													transportDistribution
												) ? (
													''
												) : (
													<FooterCard
														list={transportDistribution}
														{...{ t }}
													/>
												)
											}
											traceability
											padding={0}
										>
											{(detectEmptyObject(
												transportDistribution
											) || !validateProjectVersion()) ? (
												<NoDataToDisplay />
											) : (
												<div
													className="d-flex flex-row w-100 align-items-center"
													style={{ zIndex: 1 }}
												>
													<PieChartDashboard
														tooltip
														centerInformation={
															<span
																className="position-absolute text-dark-100"
																style={{
																	top: '50%',
																	left: '50%',
																	transform:
																		'translate(-50%,-50%)',
																	fontSize: '24px',
																}}
															>
																<span
																	className="icon-icon-suppliers font-47px"
																	style={{
																		color:
																			'#D6D6D7',
																	}}
																></span>

																<TooltipTraceabilityScore
																	top={20}
																	minWidth={130}
																>
																	<div
																		className="d-flex flex-row"
																		role="traceability-score-tooltip"
																	>
																		<div className="d-flex flex-column w-100">
																			{transportDistribution.map(
																				(item: TStaticValues) => (
																					<div
																						key={`transportDistribution-${item.name}`}
																						className="font-12px d-flex flex-row justify-content-between align-items-center mb-1"
																					>
																						<div className="d-flex align-items-center">
																							<Dot
																								color={item.color}
																							/>
																							<span>
																								{t(item.name)}
																							</span>
																						</div>
																						<span>
																							{formatNumberNoDecimals(item.value)}

																							%
																						</span>
																					</div>
																				)
																			)}
																		</div>
																	</div>
																</TooltipTraceabilityScore>
															</span>
														}
														datas={transportDistribution}
														values={transportDistribution.map((item: TStaticValues) => (item.value))}
														customStyle={{ margin: 'auto' }}
													/>
												</div>
											)}
										</ChartContainer>
									</div>
								)
							}
						</div>

						{
							validateReferenceType ? null : (
								<div
									className="d-flex col p-0 m-2 w-100"
									style={cardStyles}
								>
									<div className="col p-0 mr-2">
										<ChartContainer
											between
											title={t('manufacturing-country')}
										>
											{(detectEmptyObject(
												manufacturingCountries
											) || !validateProjectVersion()) ? (
												<NoDataToDisplay />
											) : (
												<div
													className="d-flex flex-column w-100 align-items-center overflow-auto pr-2"
													style={{ gap: '15px' }}
												>
													{manufacturingCountries[
														'project_article_supplier_manufacturing_origins'
													]?.map(
														(
															country: string,
															index: number
														) => (
															<div
																key={country}
																className="d-flex flex-column w-100"
																style={{ gap: '5px' }}
															>
																<div className="d-flex flex-row justify-content-between align-items-center font-12px">
																	<span className="text-dark-100">
																		{country}
																	</span>
																	<span className="text-dark-60">
																		{formatNumberNoDecimals(
																			parseFloat(manufacturingCountries.project_article_supplier_manufacturing_origin_shares[index])
																		)}
																		%
																	</span>
																</div>
																<ProgressBarSimple
																	tooltip={false}
																	color="black"
																	percent={
																		parseFloat(manufacturingCountries.project_article_supplier_manufacturing_origin_shares[index])
																	}
																	customStyles={{
																		width:
																			'100% !important',
																	}}
																/>
															</div>
														)
													)}
												</div>
											)}
										</ChartContainer>
									</div>

									<div className="col p-0 ml-2">
										<ChartContainer
											title={t('raw-material-country')}
											between
										>
											{(detectEmptyObject(materialCountries) || !validateProjectVersion()) ? (
												<NoDataToDisplay />
											) : (
												<div
													className="d-flex flex-column w-100 align-items-center overflow-auto pr-2"
													style={{ gap: '15px' }}
												>
													{materialCountries[
														'project_article_process_matProcessing_supplier_origin'
													]?.map(
														(
															country: string,
															index: number
														) => (
															<div
																key={country}
																className="d-flex flex-column w-100"
																style={{ gap: '5px' }}
															>
																<div className="d-flex flex-row justify-content-between align-items-center font-12px">
																	<span className="text-dark-100">
																		{country}
																	</span>
																	<span className="text-dark-60">
																		{materialCountries[
																				'project_article_process_matProcessing_supplier_origin_shares'
																			][index]
																		}
																		%
																	</span>
																</div>
																<ProgressBarSimple
																	tooltip={false}
																	color="black"
																	percent={
																		parseFloat(materialCountries.project_article_process_matProcessing_supplier_origin_shares[index])
																	}
																	customStyles={{
																		width:
																			'100% !important',
																	}}
																/>
															</div>
														)
													)}
												</div>
											)}
										</ChartContainer>
									</div>
								</div>
							)
						}

						<div
							className="d-flex col p-0 m-2 w-100"
							style={{ minWidth: '550px', height: '313px', order: validateReferenceType ? 3 : 0}}
						>
							<ChartContainer
								title={t('dashboard-traceability-articles')}
								subtitle={t(
									'dashboard-traceability-articles-subtitle'
								)}
							>
								{(traceabilityRanking.project_article_traceability_score_ranking_best.filter((item: any) => !item.Traceability_score).length > 0 || !validateProjectVersion()) ? (
									<NoDataToDisplay />
								) : (
									<div
										className="d-flex flex-row align-items-center justify-content-between"
										style={{ gap: '40px' }}
									>
										<div>
											<HeaderTable
												group={TABLE_GROUP}
												bestArticles
												traceability
											/>
											<Table
												group={TABLE_GROUP}
												articleGroupName="bestArticles"
												articleGroup={
													traceabilityRanking[
														'project_article_traceability_score_ranking_best'
													]
												}
												score='Traceability_score'
											/>
										</div>
										<div>
											<HeaderTable
												group={TABLE_GROUP}
												worstArticles
												traceability
											/>
											<Table
												group={TABLE_GROUP}
												articleGroupName="worstArticles"
												articleGroup={
													traceabilityRanking[
														'project_article_traceability_score_ranking_worst'
													]
												}
												score='Traceability_score'
											/>
										</div>
									</div>
								)}
							</ChartContainer>
						</div>

						<div
							className="d-flex col p-0 m-2 w-100"
							style={cardStyles}
						>
							<div className="col p-0 mr-2">
								<ChartContainer
									title={t(
										'raw-material-composition-distribution'
									)}
									footer={
										detectEmptyObject(rawMaterialComposition) ? (
											''
										) : (
											<FooterCard
												list={rawMaterialComposition}
												{...{ t }}
											/>
										)
									}
									traceability
									padding={0}
								>
									{(detectEmptyObject(rawMaterialComposition) || !validateProjectVersion()) ? (
										<NoDataToDisplay />
									) : (
										<div
											className="d-flex flex-row w-100 align-items-center"
											style={{ zIndex: 1 }}
										>
											<PieChartDashboard
												tooltip
												centerInformation={
													<span
														className="position-absolute text-dark-100"
														style={{
															top: '50%',
															left: '50%',
															transform:
																'translate(-50%,-50%)',
															fontSize: '24px',
														}}
													>
														<span
															className="icon-icon-material-process font-47px"
															style={{
																color:
																	'#D6D6D7',
															}}
														></span>
														<TooltipTraceabilityScore
															top={20}
															minWidth={130}
														>
															<div
																className="d-flex flex-row"
																role="traceability-score-tooltip"
															>
																<div className="d-flex flex-column w-100">
																	{rawMaterialComposition.map(
																		(item: TStaticValues) => (
																			<div
																				key={`rawMaterialComposition-${item.name}`}
																				className="font-12px d-flex flex-row justify-content-between align-items-center mb-1"
																			>
																				<div className="d-flex align-items-center">
																					<Dot
																						color={item.color}
																					/>
																					<span>
																						{t(item.name)}
																					</span>
																				</div>
																				<span>
																					{item.value}%
																				</span>
																			</div>
																		)
																	)}
																</div>
															</div>
														</TooltipTraceabilityScore>
													</span>
												}
												datas={rawMaterialComposition}
												values={rawMaterialComposition.map((item:TStaticValues) => item.value)}
												customStyle={{ margin: 'auto' }}
											/>
										</div>
									)}
								</ChartContainer>
							</div>

							<div className="col p-0 ml-2">
								<ChartContainer
									title={t('raw-material-characteristics')}
									between
								>
									{(rawMaterialCharacteristics.every((item:TContent) => item.process_names.length === 0) || !validateProjectVersion()) ? (
										<NoDataToDisplay />
									) : (
										<div
											className="d-flex flex-column w-100 align-items-center"
											style={{ gap: '15px' }}
										>
											{rawMaterialCharacteristics.map((item: TContent, index: number) => (
													<div
														key={item.name}
														className="d-flex flex-column w-100"
														style={{ gap: '5px' }}
													>
														<div className="d-flex flex-row justify-content-between align-items-center font-12px">
															<div
																className="d-flex flex-row align-items-center"
																style={{
																	gap: '5px',
																}}
															>
																<span className="text-dark-100">
																	{t(`traceability-${item.name}`)}
																</span>
																<div className="tooltipCustom">
																	<span className="icon-icon-info font-12px cursor-pointer"></span>
																	<TooltipTraceabilityScore minWidth={'max-content'} customStyles={{
																		left: 0,
																		transform: 'translateX(-27%)'
																	}}>
																		{
																			item.process_names.length > 0
																			?	<div
																					className="d-flex flex-row"
																					role="traceability-score-tooltip"
																				>
																					<div className="d-flex flex-column w-auto">
																						{
																							item.process_names.map((name: any) => (
																								<p style={{marginBottom: 8, maxWidth: '250px', overflowWrap: 'break-word'}}>{name['process_name'] ?? '--'}</p>
																							))
																						}
																					</div>
																				</div>
																			: <p style={{marginBottom: 8}}>No process name</p>
																		}
																	</TooltipTraceabilityScore>
																</div>
															</div>
															<span className="text-dark-60">
																{item.value}%
															</span>
														</div>
														<ProgressBarSimple
															tooltip={false}
															color="black"
															percent={
																item.value
															}
															customStyles={{
																width:
																	'100% !important',
															}}
														/>
													</div>
												)
											)}
										</div>
									)}
								</ChartContainer>
							</div>
						</div>
					</div>
				</div>
			)}
			<TableFooter />
		</section>
	);
};

const FooterCard = ({ list, t }: { list: any; t: any }) => (
	<div
		className={`w-100 d-flex align-items-center justify-content-end px-1  py-${
			list.length > 2 ? '2' : '1'
		}`}
		style={{
			borderTop: '1px solid #C6C7C8',
			height: 'max-content',
			minHeight: '40px',
			minWidth: '100%',
			zIndex: 1,
		}}
	>
		<div
			className="d-flex flex-wrap align-items-center h-100 justify-content-end mx-3"
			style={{ gap: '7px' }}
		>
			{list.map(({ name, color }: { name: string; color: string }) => (
				<div key={name} className="d-flex flex-row align-items-center">
					<Dot color={color} />
					<span className="font-12px">{t(name)}</span>
				</div>
			))}
		</div>
	</div>
);

const Dot = ({ color }: { color: string }) => (
	<div
		style={{
			marginRight: '3px',
			width: '12px',
			height: '12px',
			borderRadius: '50%',
			background: color,
		}}
	/>
);

export default TraceabilityDashboard;
