import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MethodologyFooter from 'UI/atoms/MethodologyFooter';
import EcodesignFormWrapperHeader from './EcodesignFormWrapperHeader';
import { IEcodesignRequest, initialReq } from './types/ecodesign';
import EcocalculatorHttp from "services/http/Ecocalculator";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

interface IProps {
	children: any, 
	request: IEcodesignRequest,
	setRequest: any,
	initialOptions: any,
	dataOptions: any, 
	setDataOptions: any,
	units: string[],
	setUnits: any,
	steps: string[], 
	currentStep: string, 
	results?: boolean | undefined,
	setStep: any,
	ecoResults: any,
	setEcoResults: any,
}

const Ecocalculator = new EcocalculatorHttp();

const EcodesignFormWrapper = (props: IProps) => {
	const { children, request, setRequest, initialOptions, dataOptions, setDataOptions, units, setUnits, steps, currentStep, setStep, results, ecoResults, setEcoResults } = props;
	const { stage, indicators, itemA, itemB } = request;
	const { t }: any = useTranslation();
	const [ disabled, setDisabled ] = useState<boolean>(true);
	const [ downloadDisabled, setDownloadDisabled ] = useState<boolean>(true);

	// Handle inital form button availability
	useEffect(() => {
		if (stage && indicators.length > 0 && units.length > 0) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [stage, indicators, units]);

	// Handle comparison button availability
	useEffect(() => {
		if (itemA.processId && itemB.processId && itemA.originId && itemB.originId && request.stage !== 'T') {
			setDisabled(false);
		} else if (itemA.processId && itemB.processId && request.stage === 'T') {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [itemA, itemB, currentStep]);

	useEffect(() => {		
		if (currentStep === 'results' && ecoResults) {
			setDownloadDisabled(false);
		} else {
			setDownloadDisabled(true);
		}
	}, [currentStep, ecoResults]);

	const calculateResults = () => {
		const results = Ecocalculator.ecoCalculator(request);
		results.then((res) => {
			const data = res.data;
			setEcoResults(data);
		});
	}

	const handleContinue = () => {
		if (!disabled) setStep('comparison');
	}

	const handleReturn = () => {
		if (currentStep === 'comparison') {
			setStep('form');
			const requestReset = {
				stage: request.stage,
				equivalences: request.equivalences,
				indicators: request.indicators,
				itemA: {categoryId: '', processId: '', originId: ''},
				itemB: {categoryId: '', processId: '', originId: ''}
			}
			setRequest(requestReset);
		} else if (currentStep === 'results') {
			setStep('comparison');
			setEcoResults(undefined);
		}
	}

	const handleCalculate = () => {
		setStep('results');
		calculateResults();
	}

	const handleNewComparison = () => {
		setRequest(initialReq);
		setUnits([]);
		setStep('form');
		setDataOptions(initialOptions);
		setEcoResults();
		setDownloadDisabled(true);
	}
	
	const GenericPdfDownloader = ({rootElementId , downloadFileName} : any) => {	
		const downloadPdfDocument = () => {
			const input = document.getElementById(rootElementId) as HTMLCanvasElement;		
			html2canvas(input)
				.then((canvas: any) => {
					const widthInMm = canvas.width * 0.2645833333;
					const heightInMm = canvas.height * 0.2645833333;

					if (widthInMm > heightInMm && heightInMm < 210 || widthInMm < heightInMm && heightInMm < 210) {
						const canvasHeight = Number((heightInMm * 297 / widthInMm).toFixed(0));
						if (canvasHeight > 210) {
							const canvasWidth = Number((widthInMm * 210 / heightInMm).toFixed(0));
							const marginLeftRight = (297 - canvasWidth) / 2;
							const pdf = new jsPDF("landscape");
							pdf.addImage(canvas, 'PNG', marginLeftRight, 0, canvasWidth, 210);
							pdf.save(`${downloadFileName}.pdf`);
						} else {
							const marginTopBottom = (210 - canvasHeight)/2;
							const pdf = new jsPDF("landscape");
							pdf.addImage(canvas, 'PNG', 0, marginTopBottom, 297, canvasHeight);
							pdf.save(`${downloadFileName}.pdf`);
						}
					} 
					else {
						const canvasWidth = Number((widthInMm * 210 / heightInMm).toFixed(0));
						if (canvasWidth > 297) {
							
							const canvasHeight = Number((heightInMm * 297 / widthInMm).toFixed(0));
							const marginTopBottom = (210 - canvasHeight)/2;
							const pdf = new jsPDF("landscape");
							pdf.addImage(canvas, 'PNG', 0, marginTopBottom, 297, canvasHeight);
							pdf.save(`${downloadFileName}.pdf`);
						} else {
							const marginLeftRight = (297 - canvasWidth) / 2;							
							const pdf = new jsPDF("landscape");
							pdf.addImage(canvas, 'PNG', marginLeftRight, 0, canvasWidth, 210);
							pdf.save(`${downloadFileName}.pdf`);
						}
					}
				})
		}
        return <button className="btn btn-transparent float-right" disabled={downloadDisabled} onClick={downloadPdfDocument}>{t('common-download')}</button>
	}

	useEffect(()=>{
        const container = document.getElementById("ecocalculator-forms-container");
		if( container && currentStep === 'comparison'){
			container.scrollTop = 0;
		}
    },[currentStep])


    return (
        <div className="d-flex flex-column flex-grow-1 overflow-auto h-100 w-100 shadow-03 bg-white-100 mb-5" style={{borderRadius: '2px'}}>
			{/* Header */}
			<div className="px-4 pt-4 bg-white-100" style={{zIndex: 1000}}>
				{currentStep !== 'results' && <EcodesignFormWrapperHeader steps={steps} currentStep={currentStep} />}
				{currentStep === 'results' && <EcodesignFormWrapperHeader steps={steps} currentStep={currentStep} results={results} />}
			</div>

			{/* Body */}
			<div className="overflow-auto h-100 px-4" id="ecocalculator-forms-container">
				{children}
			</div>

			{/* Footers */}
			<div className="border-top p-4" style={{ height: '100px', zIndex: 1000}}>
				{!results && currentStep === 'form' && 
					<button className="btn btn-primary float-right" onClick={handleContinue} disabled={disabled}>{t('common-continue')}</button>
				}

				{!results && currentStep !== 'form' && 
					<div>
						<button className="btn-transparent float-left m-0 p-0 pt-2" onClick={handleNewComparison}>{t('common-new-comparison')}</button>
						<button className="btn btn-primary float-right ml-3" onClick={handleCalculate} disabled={disabled}>{t('common-calculate')}</button>
						<button className="btn btn-tertiary float-right" onClick={handleReturn}>{t('common-return')}</button>
					</div>
				}

				{results && 
					<div className="d-flex flex-row justify-content-between align-items-center">
						<MethodologyFooter />
						<div>
							{/* <button className="btn btn-transparent float-right" onClick={handleShare}>{t('common-share')}</button> */}
							
							<GenericPdfDownloader 
								rootElementId="EcoResults" 
								downloadFileName={`EcoResults-${Date.now()}`} 
							/>
						</div>
					</div>
				}
				</div>
        </div>
    )
}
export default EcodesignFormWrapper;
