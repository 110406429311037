import React, { useMemo, useState } from "react"
import { useAccount } from "contexts/accountContext";
import { useTranslation } from "react-i18next";

import Hubspot from 'services/http/Hubspot';

type FormHudpotProps = {
    buttonText: string;
    nameFormId: string;
    styles?: any;
}

const handleSubmitHubspot = (formId: string | undefined, email: string, successMessage: string, errorMessage: string) => {
    if (formId) {
        return new Hubspot().submit(email, successMessage, errorMessage, formId)
    }

    return Promise.reject('Form ID not found');
}

export const FormHudspot = ({ buttonText, nameFormId, styles }: FormHudpotProps) => {
    const { t } = useTranslation();
    const { user } = useAccount();
    const formId = useMemo(() => process.env[nameFormId], []);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = (e: any) => {
        setLoading(true);
        e.preventDefault();
        const { email } = user;

        handleSubmitHubspot(formId, email, t("custom-dataset-notification-success"), t("wait-list-notification-error"))
        .finally(() => setLoading(false))
    }

    return (
        <>
            <form onSubmit={handleSubmit} style={styles}>
                <button
                    type="submit"
                    className='btn btn-primary d-flex justify-content-center w-100 align-items-center'
                    disabled={loading}
                >{ buttonText }</button>
            </form>
        </>
    )
}