import React from 'react';
import { useHistory } from 'react-router-dom';
import AppPaths from 'permissions/roles/AppPaths';

interface SessionExpiredModalProps {
    isOpen: boolean;
    onRedirect: () => void;
}

const SessionExpiredModal: React.FC<SessionExpiredModalProps> = ({ isOpen, onRedirect }) => {
    const history = useHistory();

    if (!isOpen) return null;

    return (
        <div
            style={{
                position: "fixed",
                inset: "0",
                zIndex: 50,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(117, 116, 127, 0.20)",
            }}
        >
            <div
                style={{
                    width: "320px",
                    height: "200px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "2px",
                    padding: "24px",
                    textAlign: "center",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h3 className='font-sfpro-medium font-14px'>
                    Session expired
                </h3>
                <p className='font-sfpro-regular font-14px'>
                    Please log in again to continue using the application.
                </p>
                    <button
                    className='font-sfpro-medium font-14px '
                    onClick={onRedirect}
                    style={{
                            width: "100%",
                            backgroundColor: "#1C1C30",
                            color: "#FFFFFF",
                            padding: "12px 32px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            border: "none"
                        }}
                    >
                        Log in
                    </button>
            </div>
        </div>
    );
};

export default SessionExpiredModal;
