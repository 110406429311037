import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SimpleTooltip } from "UI/atoms/Tooltips/Index";
import { getResultLabelCategory, getPoints } from "utils/IndexQualifications";
import { SUSTAINABILITY_INDEX_TOTAL_SCORES } from "utils/ProjectResults";
import { formatNumber } from 'utils/utils';

const SITable = (props: any) => {
	const { data } = props;
	const { t } = useTranslation();

	const categories = [
		{
			name: "Planet",
			color: "#773F0F"
		},
		{
			name: "People",
			color: "#0E343C",
		},
		{
			name: "Transparency",
			color: "#BE8F1F"
		},
		{
			name: "Circularity",
			color: "#C8B7AF"
		},
	];

	const iconByLabelCategory: any = {
		A: 'icon-icon-index-a',
		B: 'icon-icon-index-b',
		C: 'icon-icon-index-c',
		D: 'icon-icon-index-d',
		E: 'icon-icon-index-e',
	}

	const Rows: any = []

	categories.map((category) => {
		const labelCategory = getResultLabelCategory(data[category.name], category.name)
		const icon = iconByLabelCategory[labelCategory]
		const tooltipId = `SITable-${category.name}`
		const [isTooltipOpen, setIsTooltipOpen] = useState(false)

		Rows.push(
			<tr className="font-sfpro-regular text-dark-60 font-12px" style={{ borderBottom: "1px solid #D6D6D7", height: "40px" }}>
				<td style={{ width: "33%" }} className="text-left">
					{t(`dash-${category.name}`)}
				</td>
				<td style={{ width: "33%" }} className="text-left">
					<span id={tooltipId}
						onMouseEnter={() => setIsTooltipOpen(true)}
						onMouseLeave={() => setIsTooltipOpen(false)}
						className={`${icon} font-20px`} ></span>
					<SimpleTooltip
						text={t(`score-${labelCategory}-description`)}
						tooltipId={tooltipId}
						tooltipOpen={isTooltipOpen}
						styles={{ maxWidth: '250px', width: 'auto', textAlign: 'start' }}
					/>
				</td>
				<td style={{ width: "33%" }} className="text-left">
					<span className="text-dark-100 font-14px">
						{formatNumber(getPoints(data[category.name], category.name))}
					</span>
					<span className="font-sfpro-regular text-dark-60 font-14px"> {t("points").toLowerCase()}</span>
				</td>
			</tr>
		)
	})

	return (
		<table
			className="w-full"
			style={{
				width: "100%",
				height: "250px",
				minWidth: "0px",
			}}
		>
			<thead>
				<tr className="text-black-100 font-sfpro-medium font-12px" style={{ height: "45px", borderBottom: "1px solid #D6D6D7" }}>
					<td style={{ width: "33%" }} className="text-left">{t("category")}</td>
					<td style={{ width: "33%" }} className="text-left">{t("ecoScore")}</td>
					<td style={{ width: "33%" }} className="text-left">{t("qualification")}</td>
				</tr>
			</thead>
			<tbody>
				{Rows}
			</tbody>
		</table>
	);
};

export default SITable;
