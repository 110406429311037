import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import AppPaths from '../../../permissions/roles/AppPaths';
import { useLocation, useHistory } from 'react-router-dom';
import { useTable } from 'contexts/tableContext';
import { ModalProjectInfo } from 'UI/organism/ModalProjectInfo';
import { validateReferenceType } from 'utils/utils';

import './ScopeSelection.scss';

interface ScopeSelectionInterface {
	scopes: string[];
	projectSelected: any;
	isLoading: boolean;
}

const DEFAULT_DASHBOARD = 'sidebar-nav-articles'

export const ScopeSelection = ({scopes, projectSelected, isLoading}: ScopeSelectionInterface) => {
	const { t } = useTranslation()
	const { getSelectedView, handleLoading } = useTable()
	const [showProjectInfo, setShowProjectInfo] = React.useState<boolean>(false)
	const [scopeSelected, setScopeSelected] = React.useState<string>(t(DEFAULT_DASHBOARD));
	const location = useLocation()
	const h = useHistory()

	const getScope = () => location.pathname.split('/')[3]

	const validatePlmDashboard = () => location.pathname.includes('plm/dashboard')

	const validatePlmPath = () => location.pathname.includes('/plm')

	useEffect(() => {
		if (!validatePlmPath()) {
			if (validateReferenceType(projectSelected)) {
				setScopeSelected('Materials')
			} else setScopeSelected('Articles')
		} else {
			const scope = getScope()
			if (!validatePlmDashboard()) setScopeSelected(scope)
			else {
				const localeKey = validateReferenceType(projectSelected) ? 'sidebar-nav-materials' : DEFAULT_DASHBOARD
				setScopeSelected(t(localeKey))
			}
		}
	}, [location])

	const handleSelection = (item: string) => {
		setScopeSelected(item);
		switch (item) {
			case 'articles':
				redirectScope(AppPaths.traceability);
				break;
			case 'suppliers':
				redirectScope(AppPaths.traceabilitySupplier);
				break;
			case 'materials':
				redirectScope(AppPaths.traceabilityMaterials);
				break;
			case 'logistics':
				redirectScope(AppPaths.traceabilityLogistics);
				break;
		}
	};

	const redirectScope = (uri: string) => {
		handleLoading(true)
		h.push(uri);
		getSelectedView()
	}

	const handleScopes = () => {
		if (scopes.length > 1) {
			const scope = getScope()

			const index = scopes.findIndex(
				(item) => item.toLowerCase() === scope
			);
			setScopeSelected(scopes[index]);
		}
	};

	useEffect(() => {
		if (!validatePlmDashboard()) handleScopes()
	}, []);

	return (
		<UncontrolledDropdown 
			className={`position-relative d-flex flex-row align-items-center nav-link px-0 py-1 pl-2 pr-1 text-dark-100`}
			style={{ width: 'max-content', opacity: (isLoading && !validatePlmDashboard()) ? 0.5 : 1 }}
			disabled={isLoading}
		>
			<DropdownToggle 
				tag="div" 
				className="d-flex align-items-center cursor-pointer bg-transparent border-0 p-0"
			>
				<span className={`font-14px font-sfpro-regular text-overflow-ellipsis overflow-hidden text-dark-100 text-capitalize`}>
					{scopeSelected}
				</span>
				{((validatePlmPath() && !validatePlmDashboard()) && scopes.length > 1) && (
					<div className={`d-flex align-items-center justify-content-center`} style={{ height: '32px', width: '32px' }}>
						<span className={`icon-icon-chevron-down font-30px text-dark-100 scope-rotate-icon`} />
					</div>
				)}
			</DropdownToggle>
			{((validatePlmPath() && !validatePlmDashboard()) && scopes.length > 1) && (
				<DropdownMenu className='rounded-02' right style={{ minWidth: '280px', maxHeight: '320px', overflowY: 'auto' }}>
					{scopes?.map((item: string, index: number) => (
						<DropdownItem 
							key={index}
							onClick={() => handleSelection(item)}
							className={`d-flex align-items-center ${scopeSelected === item ? 'selected-project font-sfpro-medium' : ''}`}
						>
							{scopeSelected === item && (
								<span className="icon-icon-tick mr-2 text-dark-100 font-18px" />
							)}
							<div className={`${scopeSelected === item ? 'font-sfpro-medium' : 'font-sfpro-regular'} text-overflow-ellipsis overflow-hidden text-capitalize`}>
								{item}
							</div>
						</DropdownItem>
					))}
				</DropdownMenu>
			)}

			<ModalProjectInfo
				isShow={showProjectInfo}
				handleClose={() => setShowProjectInfo(false)}
				projectData={projectSelected}
			/>
		</UncontrolledDropdown>
	);
};
