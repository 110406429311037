import React, { useEffect, useState } from 'react';
import { CardGrid } from 'UI/molecules/CardGrid/Index';
import { LibraryResource } from 'loader/library-loader';
import { LayoutLibrary } from './LayoutLibrary';
import { filtersLibrary } from '../../../constants';
import LibraryHttp from 'services/http/Library';

function Library() {
	const library = new LibraryHttp();
	const [filterSelected, setFilterSelected] = useState(filtersLibrary[0]);
	const [searchText, setSearchText] = useState('');
	const [orderBy, setOrderBy] = useState<'newest' | 'oldest'>('newest');
	const [data, setData] = useState<LibraryResource[]>([]);

	const handleFilter = (key: string, value: string) => setFilterSelected(value);
	const handleSearch = (key: string, value: string) => setSearchText(value);
	const handleOrder = (key: string, value: 'newest' | 'oldest') => setOrderBy(value);

	useEffect(() => {
		library
		.getAll()
		.then((res) => {
			setData(res.data);
		})
	}, []);

	const sortData = (items: LibraryResource[], order: 'newest' | 'oldest') => {
		return [...items].sort((a, b) => {
			const dateA = new Date(a.createdAt).getTime();
			const dateB = new Date(b.createdAt).getTime();
			const comparison = dateA - dateB;
			return order === 'newest' ? -comparison : comparison;
		});
	};

	const filteredAndSortedData = sortData(
		data.filter(item => 
			(filterSelected === filtersLibrary[0] || item.category === filterSelected) &&
			(searchText === '' || item.title.toLowerCase().includes(searchText.toLowerCase()))
		),
		orderBy
	);

	return (
		<section className="" style={{ height: '97%', overflowY: 'auto', borderRadius: '2px' }}>
			<LayoutLibrary
				keyName="library"
				title=""
				filters={filtersLibrary}
				filterSelected={filterSelected}
				handleFilter={handleFilter}
				orderBy={orderBy}
				handleOrder={handleOrder}
                searchText={searchText}
                handleSearch={handleSearch}
			>
				<CardGrid data={filteredAndSortedData} />
			</LayoutLibrary>
		</section>
	);
}

export default Library;
