import { useProjects } from 'contexts/projectsContext';
import { useTable } from 'contexts/tableContext';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SaveIcon } from 'UI/atoms/SaveIcon';
import SearchBar from 'UI/atoms/Searchbar';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';

import Toggle from 'components/Common/toggle/toggle';
import { GenericPdfDownloader } from 'UI/molecules/OnePageHeader/Index';
import { downloadFileName } from '../../../constants';
import { formatDate } from 'utils/utils';

const relationFilterCategories:any = {
	articleCategoryId: 'articleCategories',
	materialCategoryId: 'materialCategories',
	materialId: 'materials',
	originId: 'origins',
	rawMaterialCategoryId: 'rawMaterialCategories',
	rawMaterialProcessId: 'rawMaterialProcesses',
	transportCategoryName: 'transportCategories',
	wetProcessId: 'wetProcesses'
}
import { SelectFavorite } from 'UI/atoms/SelectFavorite';
import SelectorViews from 'UI/molecules/SelectorViews';
import NewFilterUI from '../PageHeader/newFilterUI';

interface Props {
	isLoading: boolean;
}

const CEI = 'cei';
const DISABLED_OPTIONS = [''];

const handleDisableOptions = (id: string) => DISABLED_OPTIONS.includes(id);

const TableHeaderOptions = (props: Props) => {
	const {
		setOpenColumnSelectorModal,
		customView,
		selectedView,
		setModalViewActionSelected,
		enableBenchmarkData,
		setEnableBenchmarkData,
		setOpenFiltersModal,
		setSectionFilterSelectedToOpen,
		filtersApplied,
	} = useTable();
	const {
		openModalSaveChanges,
		setOpenModalSaveChanges,
		filtersCategoriesByProject,
		projectSelected,
		isDashboard,
	} = useProjects();
	const h = useHistory();
	const path = h.location.pathname;
	const wPath = window.location.pathname;
	const generalProduct = wPath.split('/')[1];
	const product = path.split('/')[1];
	const scope = path.split('/')[2];
	const a = path.split('/')[3];
	const { t } = useTranslation();

	const formatedActualDate = formatDate();
	const projectSelectedName = projectSelected?.name;
	const textDownloadFile = downloadFileName[product];
	const buildedFileName = `BCome ${textDownloadFile} Dashboard - ${projectSelectedName} - ${formatedActualDate}`;

	const { isLoading } = props;

	useEffect(() => {
		if (openModalSaveChanges.open)
			setModalViewActionSelected('saveWarning');
	}, [openModalSaveChanges]);

	const handleToggleColumns = () => {
		setOpenColumnSelectorModal(true);
	};

	const toggleColumnsBtn = {
		id: 'toggleColumns',
		icon: 'columns',
		action: handleToggleColumns,
	};

	const IconAction = ({ btn, iconActive, setIconActive }: any) => {
		const [isTooltipOpen, setIsTooltipOpen] = useState(false);
		const btnId = `table-btn-${btn.id}`;

		const handleIconActive = () => {
			if (handleDisableOptions(btn.id)) return;

			if (btn.icon === 'download' && openModalSaveChanges.customView) {
				setOpenModalSaveChanges({
					...openModalSaveChanges,
					open: true,
				});
				return;
			}

			setIconActive(btn.id);
			btn.action();
		};

		return (
			<>
				<div
					id={btnId}
					onMouseEnter={() => setIsTooltipOpen(true)}
					onMouseLeave={() => setIsTooltipOpen(false)}
					className={`
						d-flex align-items-center justify-content-center cursor-pointer rounded-08 text-dark-60 text-dark-100-hover
						${
							iconActive == btn.id && !enableBenchmarkData
								? 'bg-gray-01 text-dark-100'
								: ''
						}
						${
							handleDisableOptions(btn.id)
								? 'd-flex align-items-center justify-content-center rounded-08 block-dashboard'
								: ''
						}
					`}
					onClick={handleIconActive}
				>
					<span
						className={`icon-icon-${btn.icon} font-20px`}
						style={{ margin: '6px' }}
					></span>
				</div>
				<SimpleTooltip
					tooltipId={btnId}
					tooltipOpen={isTooltipOpen}
					text={t(`tooltip-btn-${btn.id}`)}
				/>
			</>
		);
	};
	const metricActions = scope === 'cei' ? [] : [toggleColumnsBtn];

	const iconActionsByPath: any = {
		metrics: metricActions,
		evaluation: [],
		diagnosis: [toggleColumnsBtn],
		digitization: [],
		traceability: [],
	};

	const [iconActive, setIconActive] = useState('');
	const pathWithTableMenu = ['digitization'];

	const [openTooltipIndustryStandard, setOpenTooltipIndustryStandard] =
		useState(false);
	const tooltipIndustryStandardId = 'switch-lca-benchmark';

	const handleOpenTooltipIndustryStandard = (isOpen: boolean) => () =>
		setOpenTooltipIndustryStandard(isOpen);

	useEffect(() => {
		setTimeout(() => {
			setIconActive('');
		}, 500);
	}, [iconActive]);

	const [ show, setShow ] = useState<boolean>(false);
	const itemsSelected:any = {}
    for (const filterIdGroup in filtersApplied) {
        if (filtersApplied[filterIdGroup].length > 0) {
            itemsSelected[filterIdGroup] = []
            const namesArr = filtersApplied[filterIdGroup].map((item:string) => {
                return filtersCategoriesByProject[relationFilterCategories[filterIdGroup]].find((g:any) => g.id ? g.id === item : g.name === item).name
            })
            namesArr.forEach((name:string) => itemsSelected[filterIdGroup].push(name))
        }
    }

	const handleOpenFiltersModal = () => {
        if( openModalSaveChanges.customView ){
            setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
        }else{
            setOpenFiltersModal(true);
            setSectionFilterSelectedToOpen('');
        }
    }

	const handleFilter = useCallback(() => {
		return !(product === "digitization" || scope === "dashboard" || a !== 'articles' || product === 'cei')
	}, [product, scope])

	const pickFavorites = () => {
		if ( !h.location.search.includes('filter=favorites') ) {
			h.push(`?filter=favorites`)
		}
	}

	return (
		<div
			className="d-flex flex-row align-items-center justify-content-between border-bottom py-1 mb-3 bg-white-100"
			style={{ minHeight: '65px' }}
		>
			<div className="d-flex flex-row align-items-center">
				<div className='ml-2'>
					<NewFilterUI />
				</div>
				{
					handleFilter() &&
					<div
						className="font-12px ml-2"
						onMouseEnter={() => !isLoading && setShow(true)}
						onMouseLeave={() => !isLoading && setShow(false)}
					>
						<span
							className={`font-14px text-dark-100 ${!isLoading ? 'cursor-pointer' : ''} ml-1`}
							onClick={handleOpenFiltersModal}
							style={{
								textDecoration: show ? "underline" : ""
							}}
						>
							{t("filters")} ({Object.values(itemsSelected).flat().length})
						</span>
					</div>
				}
			</div>

			{selectedView && (
				<div className="d-flex flex-row align-items-center justify-content-between pr-3">
					{scope === 'lca' && selectedView && (
						<div className="row d-flex align-items-center justify-content-end mb-1 mt-1" style={{ margin: '6px' }}>
							<div className="col-md-2">
								<Toggle
									onChange={(value: boolean) =>
										setEnableBenchmarkData(value)
									}
									checked={enableBenchmarkData}
									disabled={isLoading}
									styles={{ marginLeft: '5px' }}
								/>
							</div>
							<p
								id={tooltipIndustryStandardId}
								className="font-sfpro-regular font-12px text-dark-100 pl-4 m-0"
								onMouseEnter={handleOpenTooltipIndustryStandard(
									true
								)}
								onMouseLeave={handleOpenTooltipIndustryStandard(
									false
								)}
							>
								{t('switch-lca-benchmark')}
							</p>
							<SimpleTooltip
								tooltipId={tooltipIndustryStandardId}
								tooltipOpen={openTooltipIndustryStandard}
								text={t('switch-lca-benchmark-tooltip')}
								styles={{ textAlign: 'left' }}
							/>
						</div>
					)}
					<SearchBar />
					<SelectorViews />
					{iconActionsByPath[product] &&
						iconActionsByPath[product].map((btn: any) => (
							<IconAction
								btn={btn}
								iconActive={iconActive}
								setIconActive={setIconActive}
							/>
						))}
					{customView && <SaveIcon />}
					{
						scope !== 'data-collection' && (
							<SelectFavorite select={false} onClick={() => pickFavorites()} />
						)
					}
					<GenericPdfDownloader
						rootElementId={`${product}-dashboard`}
						downloadFileName={buildedFileName}
						tableDownload={!isDashboard}
						disabled={scope === CEI}
					/>
				</div>
			)}
		</div>
	);
};

export default TableHeaderOptions;
