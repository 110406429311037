import AppPaths from 'permissions/roles/AppPaths';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { tokenManager } from '../services/AuthInterceptor';
import SessionExpirationManager from '../services/SessionExpirationManager';
import StorageAuth from '../services/storage/Auth';
import SessionExpiredModal from './Modals/SessionExpiredModal';

const SessionManager: React.FC = () => {
    const [modalState, setModalState] = useState({ isExpired: false, isWarning: false });
    const location = useLocation();
    const SAuth = new StorageAuth();

    const handleSessionState = useCallback(
        (state: { isExpired: boolean; isWarning: boolean }) => {
            if (!location.pathname.includes('/login')) {
                setModalState(state);
            } else {
                setModalState({ isExpired: false, isWarning: false });
            }
        },
        [location.pathname]
    );

    useEffect(() => {
        const sessionManager = SessionExpirationManager.getInstance();
        sessionManager.addListener(handleSessionState);
        
        // Iniciar el token manager cada vez que el componente se monta
        tokenManager.startTokenRefresh();

        return () => {
            sessionManager.removeListener(handleSessionState);
            // Detener el token manager cuando el componente se desmonta
            tokenManager.stopTokenRefresh();
        };
    }, [handleSessionState]);

    const handleRedirectToLogin = () => {
        const sessionManager = SessionExpirationManager.getInstance();
        sessionManager.resetSession();
        SAuth.removeSession();
        window.location.href = AppPaths.login;
    };

    return (
        <>
            <SessionExpiredModal
                isOpen={modalState.isExpired || modalState.isWarning}
                onRedirect={handleRedirectToLogin}
            />
        </>
    );
};

export default SessionManager;
