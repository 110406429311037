export class Favorites {
  public saveFavorites(favorite: string): void {
    return localStorage.setItem('favorites', favorite);
  }

  public removeFavorites() {
    return localStorage.removeItem('favorites');
  }

  public getCurrentfavorites(): string[] {
    const favorites = localStorage.getItem('favorites') || 'none';

    if (favorites === 'none') {
      return [];
    }

    return JSON.parse(favorites);
  }

  public saveGlobalFavorites(favorites: string[]): void {
    // Asegurarse de que no hay duplicados antes de guardar
    const uniqueFavorites = this.removeDuplicates(favorites);
    return localStorage.setItem('global_favorites', JSON.stringify(uniqueFavorites));
  }

  public getGlobalFavorites(): string[] {
    const favorites = localStorage.getItem('global_favorites') || 'none';

    if (favorites === 'none') {
      return [];
    }

    return JSON.parse(favorites);
  }

  private removeDuplicates(arr: string[]): string[] {
    return Object.keys(arr.reduce((acc: { [key: string]: boolean }, curr) => {
      acc[curr] = true;
      return acc;
    }, {}));
  }

  public mergeWithGlobalFavorites(currentFavorites: string[]): string[] {
    const globalFavorites = this.getGlobalFavorites();
    console.log('Global favorites:', globalFavorites);
    // Asegurarse de que no hay duplicados en la fusión
    return this.removeDuplicates([...currentFavorites, ...globalFavorites]);
  }
}

export default new Favorites();
