import React from 'react';
import { useTranslation } from 'react-i18next';
import { tableGetColWidth } from 'components/Table/utils/TableUtils';
import { ITableSchema } from 'components/Table/types/TableTypes';

/**
 * Custom list Header;
 * @param props
 * @returns
 */
 const ProjectResultListHeader = (props: any) => {
	const { t } = useTranslation();
	const schema : ITableSchema = props.schema;
	let prevGroup: string|null = null;
	const filters = Object.entries(schema.columns)
		.filter(([key, value]) => value.show)
		.map(([key, column]) => {
			const colW = tableGetColWidth(schema, key);
			let groupHtml = <h4 className="mb-0 font-sfpro-medium text-dark-80" style={{display: 'none'}}>&nbsp;</h4>; // Para que se vea todo alineado;
			if (schema.columnGroups && column.group) {
				const colGroup = column.group;
				if (colGroup != prevGroup) {
					groupHtml = (
						<h4 className="mb-0 mt-1 font-sfpro-medium text-dark-80 text-nowrap letter-spacing-normal">
							{t(`${colGroup}`)}
							{colGroup === 'fossilFuels' && <span className="font-sfpro-regular text-dark-60 text-nowrap letter-spacing-normal font-18px">{t(`${colGroup}-abiotic`)}</span>}
						</h4>
					)
					prevGroup = colGroup;
				}
			}

			
			return (
				<div
				key={key}
				className={`${column.tableHeaderCssClass} align-items-center text-dark-80 font-sfpro-medium letter-spacing-normal ${column.group !== 'traceabilityImpacts' ? 'text-overflow-ellipsis overflow-hidden pr-2' : ''}`}
				style={{ width: `${colW}%`, lineHeight: '32px', fontSize: '18px' }}>					
						{groupHtml}
						{column.label === "lH2O" && <span>m<sup>3</sup> eq</span>}
						{column.label === "kgCO2" && <span>kg CO<sub>2</sub> eq</span>}
						{column.label === "gPO4" && <span>g PO<sub>4</sub><sup>3-</sup> eq</span>}
						{column.label !== "lH2O" && column.label !== "kgCO2" && column.label !== "gPO4" && 
						<div className="text-overflow-ellipsis overflow-hidden pr-2">
							<span className="text-overflow-ellipsis overflow-hidden pr-2">{t(column.label)}</span>
						</div>
						}						
					</div>
			)
		})
		
	return (
		<div className="w-100 pt-3 pb-3 pl-3 d-flex flex-row align-items-center text-overflow-ellipsis overflow-hidden pr-2" style={{paddingTop: "8px"}}>
			{filters}
		</div>
	);
};

export default ProjectResultListHeader;
